export const FilterComponent = ({filterText, onFilter, onClear, placeholder}) => (
	<>
		<input
			className="h-8 w-52 rounded-l-md border pl-4 pr-8"
			id="search"
			type="text"
			placeholder={placeholder}
			aria-label="Search Input"
			value={filterText}
			onChange={onFilter}
		/>
		<button onClick={onClear} className="flex h-8 w-12 items-center justify-center rounded-r-md bg-blue-600 text-white">
			X
		</button>
	</>
);
