import {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {useForm, Controller} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {useSelector, useDispatch} from "react-redux";
import {toast} from "react-toastify";
import Select from "react-select";

import {cardActions, clientActions, advActions} from "_store";
import {Header, LoadingButtons} from "_components";
import {CardData, CardTypeData} from "utils";

export function AddAdv() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const {cards, cardAdd} = useSelector(x => x.cards);
	const {clients, clientAdd} = useSelector(x => x.clients);
	const {advAdd} = useSelector(x => x.advs);

	const [submitted, setSubmitted] = useState(false);

	const [selectedClients, setSelectedClients] = useState([]);
	const [addClient, setAddClient] = useState({show: false, idClient: null});
	const [addCard, setAddCard] = useState({
		showFace: false,
		idCardFace: null,
		clientsActiveFace: false,
		showGoogle: false,
		idCardGoogle: null,
		clientsActiveGoogle: false,
	});
	//const [selectedOption, setSelectedOption] = useState(null);

	const [selectedCards, setselectedCards] = useState([]);

	// form validation rules Adv
	const validationSchema = Yup.object().shape({
		desc: Yup.string().required("This field is required"),
		client: Yup.object().shape({
			value: Yup.string().required("This field is required"),
		}),
		cardGoogle: Yup.object().shape({
			value: Yup.string().required("This field is required"),
		}),
		cardFacebook: Yup.object().shape({
			value: Yup.string().required("This field is required"),
		}),
	});

	// form validation rules Client
	const validationSchemaClient = Yup.object().shape({
		name: Yup.string().required("This field is required"),
		desc: Yup.string().required("This field is required"),
	});

	// form validation rules Card
	const validationSchemaCard = Yup.object().shape({
		number: Yup.string().required("This field is required"),
		desc: Yup.string().required("This field is required"),
	});

	const formOptions = {resolver: yupResolver(validationSchema)};
	const formOptionsClient = {resolver: yupResolver(validationSchemaClient)};
	const formOptionsCard = {resolver: yupResolver(validationSchemaCard)};

	// get functions to build form with useForm() hook
	const {register, handleSubmit, formState, reset, control, setValue} = useForm(formOptions);
	const {errors, isSubmitting} = formState;

	// get functions to build form with useForm() hook Client
	const {register: registerClient, handleSubmit: handleSubmitClient, formState: formStateClient, reset: resetClient} = useForm(formOptionsClient);
	const {errors: errorsClient, isSubmitting: isSubmittingClient} = formStateClient;

	// get functions to build form with useForm() hook Card Google
	const {register: registerGoogle, handleSubmit: handleSubmitGoogle, formState: formStateGoogle, reset: resetGoogle} = useForm(formOptionsCard);
	const {errors: errorsGoogle, isSubmitting: isSubmittingGoogle} = formStateGoogle;

	// get functions to build form with useForm() hook Card Facebook
	const {
		register: registerFacebook,
		handleSubmit: handleSubmitFacebook,
		formState: formStateFacebook,
		reset: resetFacebook,
	} = useForm(formOptionsCard);
	const {errors: errorsFacebook, isSubmitting: isSubmittingFacebook} = formStateFacebook;

	function onSubmit(data) {
		setSubmitted(true);
		data.client = parseInt(data.client.value);
		data.cardGoogle = parseInt(data.cardGoogle.value);
		data.cardFacebook = parseInt(data.cardFacebook.value);
		return dispatch(advActions.add({data}));
	}
	function onSubmitClient({name, desc, state}) {
		setSubmitted(true);
		return dispatch(clientActions.add({name, desc, state}));
	}
	function onSubmitCard(data) {
		setSubmitted(true);
		if (data.isCompany === "true") {
			data.isCompany = true;
		} else if (data.isCompany === "false") {
			data.isCompany = false;
		}
		return dispatch(cardActions.add({data}));
	}

	function activeClient() {
		setAddClient(existingValues => ({
			...existingValues,
			show: true,
		}));
	}
	function disabledClient() {
		setAddClient(existingValues => ({
			...existingValues,
			show: false,
		}));
	}

	function activeCard(type) {
		if (type === "face")
			setAddCard(existingValues => ({
				...existingValues,
				showFace: true,
			}));
		else if (type === "google")
			setAddCard(existingValues => ({
				...existingValues,
				showGoogle: true,
			}));
	}
	function disableCard(type) {
		if (type === "face")
			setAddCard(existingValues => ({
				...existingValues,
				showFace: false,
			}));
		else if (type === "google")
			setAddCard(existingValues => ({
				...existingValues,
				showGoogle: false,
			}));
	}
	const handleChangeFace = event => {
		if (event.target.value === "false") {
			setAddCard(existingValues => ({
				...existingValues,
				clientsActiveFace: true,
			}));
		} else if (event.target.value === "true") {
			setAddCard(existingValues => ({
				...existingValues,
				clientsActiveFace: false,
			}));
		}
	};
	const handleChangeGoogle = event => {
		if (event.target.value === "false") {
			setAddCard(existingValues => ({
				...existingValues,
				clientsActiveGoogle: true,
			}));
		} else if (event.target.value === "true") {
			setAddCard(existingValues => ({
				...existingValues,
				clientsActiveGoogle: false,
			}));
		}
	};

	/*Client*/
	useEffect(() => {
		if (!isSubmittingClient && submitted && !clientAdd.error) {
			resetClient();
			dispatch(clientActions.getAll());
			toast.success("Datos adicionados correctamente.");
			setSubmitted(false);
			setAddClient(existingValues => ({
				...existingValues,
				idClient: clientAdd.id,
			}));
			setValue("client", {value: clientAdd.id});
			disabledClient();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSubmittingClient]);
	/*--------*/

	/*Card*/
	useEffect(() => {
		if (!isSubmittingFacebook && submitted && !cardAdd.error) {
			resetFacebook();
			dispatch(cardActions.getAll());
			toast.success("Datos adicionados correctamente.");
			setSubmitted(false);
			setAddCard(existingValues => ({
				...existingValues,
				idCardFace: cardAdd.id,
			}));
			setValue("cardFacebook", {value: cardAdd.id});
			disableCard("face");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSubmittingFacebook]);
	useEffect(() => {
		if (!isSubmittingGoogle && submitted && !cardAdd.error) {
			resetGoogle();
			dispatch(cardActions.getAll());
			toast.success("Datos adicionados correctamente.");
			setSubmitted(false);
			setAddCard(existingValues => ({
				...existingValues,
				idCardGoogle: cardAdd.id,
			}));
			setValue("cardGoogle", {value: cardAdd.id});
			disableCard("google");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSubmittingGoogle]);
	/*--------*/

	useEffect(() => {
		if (!isSubmitting && submitted && !advAdd.error) {
			reset();
			toast.success("Datos adicionados correctamente.");
			setSubmitted(false);
			navigate("/advertising/list/");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSubmitting]);

	useEffect(() => {
		dispatch(clientActions.getAll());
		dispatch(cardActions.getAll());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (clients.length) {
			const options = clients.map(client => ({
				label: client.name,
				value: client.id,
			}));
			setSelectedClients(options);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [clients]);

	useEffect(() => {
		if (cards.length >= 0) {
			const options = cards.map(card => ({
				label: `${card.number}${CardData(card.cardType, card.cardBank)} - ${card.isCompany ? "Compañía" : "Cliente"}`,
				value: card.id,
			}));
			options.unshift({
				label: "No tiene",
				value: 0,
			});
			setselectedCards(options);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cards]);

	return (
		<div className="margin-move ml-[300px] p-3">
			<Header />
			{advAdd.error && (
				<div className="relative mb-3 rounded border border-red-400 bg-red-100 px-4 py-3 text-red-700" role="alert">
					ERROR: {advAdd.error.message}
				</div>
			)}
			<div className="bg-white px-4 pb-5 pt-3">
				<div className="mb-6 text-xl">Agregar Publicidad Sources</div>
				{(clients.loading || cards.loading) && <div className="">Cargando...</div>}
				{clients && cards && !clients.loading && !clients.error && !cards.loading && !cards.error && (
					<>
						<div className="flex items-start">
							<div className="w-96 rounded border p-3">
								<div className="mb-6 text-xl uppercase">Nueva Publicidad</div>
								<form onSubmit={handleSubmit(onSubmit)} className="max-w-sm">
									<div className="mb-5">
										<div className="mb-2 flex items-center justify-between">
											<label className="block text-sm font-bold text-gray-700" htmlFor="selectClients">
												Cliente
											</label>
											<button
												type="button"
												onClick={activeClient}
												className="focus:shadow-outline flex items-center justify-center rounded bg-blue-500 px-2 font-bold uppercase text-white hover:bg-blue-700 focus:outline-none"
											>
												Adicionar
											</button>
										</div>
										<Controller
											name="client"
											control={control}
											render={({field}) => (
												<Select
													{...field}
													options={selectedClients}
													value={selectedClients?.find(({value}) => value === addClient.idClient)}
													isDisabled={addClient.idClient ? true : false}
												/>
											)}
										/>
									</div>
									<div className="mb-5">
										<div className="mb-2 flex items-center justify-between">
											<label className="mb-2 block text-sm font-bold text-gray-700" htmlFor="cardGoogle">
												Tarjeta para Google
											</label>
											<button
												onClick={() => activeCard("google")}
												type="button"
												className="focus:shadow-outline flex items-center justify-center rounded bg-blue-500 px-2 font-bold uppercase text-white hover:bg-blue-700 focus:outline-none"
											>
												Adicionar
											</button>
										</div>
										<Controller
											name="cardGoogle"
											control={control}
											render={({field}) => (
												<Select
													{...field}
													options={selectedCards}
													value={selectedCards?.find(({value}) => value === addCard.idCardGoogle)}
													isDisabled={addCard.idCardGoogle ? true : false}
												/>
											)}
										/>
										<p className="text-xs italic text-red-500">{errors.checkedGoogle?.message}</p>
									</div>
									<div className="mb-5">
										<div className="mb-2 flex items-center justify-between">
											<label className="mb-2 block text-sm font-bold text-gray-700" htmlFor="cardFacebook">
												Tarjeta para Facebook
											</label>
											<button
												onClick={() => activeCard("face")}
												type="button"
												className="focus:shadow-outline flex items-center justify-center rounded bg-blue-500 px-2 font-bold uppercase text-white hover:bg-blue-700 focus:outline-none"
											>
												Adicionar
											</button>
										</div>
										<Controller
											name="cardFacebook"
											control={control}
											render={({field}) => (
												<Select
													{...field}
													options={selectedCards}
													value={selectedCards?.find(({value}) => value === addCard.idCardFace)}
													isDisabled={addCard.idCardFace ? true : false}
												/>
											)}
										/>
									</div>
									<div className="mb-5">
										<label className="mb-2 block text-sm font-bold text-gray-700" htmlFor="desc">
											Descripción
										</label>
										<textarea
											{...register("desc")}
											placeholder="Descripción"
											className={`focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none ${
												errors.desc ? "border-red-500" : ""
											}`}
										/>
										<p className="text-xs italic text-red-500">{errors.desc?.message}</p>
									</div>
									<div className="mb-8">
										<label className="mb-2 block text-sm font-bold text-gray-700" htmlFor="state">
											Estado
										</label>
										<div className="relative inline-block w-64">
											<select
												{...register("state")}
												className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
											>
												<option value="1">Activo</option>
												<option value="0">Inactivo</option>
											</select>
											<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
												<svg className="h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
													<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
												</svg>
											</div>
										</div>
									</div>
									<div className="flex w-64 items-center justify-between">
										<button
											disabled={isSubmitting}
											className="focus:shadow-outline flex w-full items-center justify-center rounded bg-blue-500 px-4 py-2 font-bold uppercase text-white hover:bg-blue-700 focus:outline-none"
										>
											{isSubmitting && <LoadingButtons />}
											Adicionar
										</button>
										<Link
											to={"/advertising/list/"}
											className="focus:shadow-outline ml-3 flex w-full items-center justify-center rounded bg-slate-400 px-4 py-2 font-bold uppercase text-white hover:bg-slate-500 focus:outline-none"
										>
											Cancelar
										</Link>
									</div>

									<div className=""></div>
								</form>
							</div>
							{addClient.show && (
								<div className="ml-3 w-96 rounded border p-3">
									<div className="mb-6 text-xl uppercase">Nuevo Cliente</div>
									<form onSubmit={handleSubmitClient(onSubmitClient)} className="max-w-sm">
										<div className="mb-5">
											<label className="mb-2 block text-sm font-bold text-gray-700" htmlFor="name">
												Nombre
											</label>
											<input
												type="text"
												{...registerClient("name")}
												placeholder="Nombre"
												className={`focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none ${
													errorsClient.name ? "border-red-500" : ""
												}`}
											/>
											<p className="text-xs italic text-red-500">{errorsClient.name?.message}</p>
										</div>
										<div className="mb-5">
											<label className="mb-2 block text-sm font-bold text-gray-700" htmlFor="desc">
												Descripción
											</label>
											<textarea
												{...registerClient("desc")}
												placeholder="Descripción"
												className={`focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none ${
													errorsClient.desc ? "border-red-500" : ""
												}`}
											/>
											<p className="text-xs italic text-red-500">{errorsClient.desc?.message}</p>
										</div>
										<div className="mb-8">
											<label className="mb-2 block text-sm font-bold text-gray-700" htmlFor="state">
												Estado
											</label>
											<div className="relative inline-block w-64">
												<select
													{...registerClient("state")}
													className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
												>
													<option value="1">Activo</option>
													<option value="0">Inactivo</option>
												</select>
												<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
													<svg className="h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
														<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
													</svg>
												</div>
											</div>
										</div>
										<div className="flex w-64 items-center justify-between">
											<button
												disabled={isSubmittingClient}
												className="focus:shadow-outline flex w-full items-center justify-center rounded bg-blue-500 px-4 py-2 font-bold uppercase text-white hover:bg-blue-700 focus:outline-none"
											>
												{isSubmittingClient && <LoadingButtons />}
												Adicionar
											</button>
											<button
												onClick={disabledClient}
												className="focus:shadow-outline ml-3 flex w-full items-center justify-center rounded bg-slate-400 px-4 py-2 font-bold uppercase text-white hover:bg-slate-500 focus:outline-none"
											>
												Cancelar
											</button>
										</div>
									</form>
								</div>
							)}
							{addCard.showFace && (
								<div className="ml-3 w-96 rounded border p-3">
									<div className="mb-6 text-xl uppercase">Nueva Tarjeta Face</div>
									<form onSubmit={handleSubmitFacebook(onSubmitCard)} className="max-w-sm">
										<div className="mb-5">
											<label className="mb-2 block text-sm font-bold text-gray-700" htmlFor="number">
												Número
											</label>
											<input
												type="text"
												maxLength={4}
												{...registerFacebook("number")}
												placeholder="Número"
												className={`focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none ${
													errorsFacebook.number ? "border-red-500" : ""
												}`}
											/>
											<p className="text-xs italic text-red-500">{errorsFacebook.number?.message}</p>
										</div>
										<div className="mb-5">
											<label className="mb-2 block text-sm font-bold text-gray-700" htmlFor="desc">
												Descripción
											</label>
											<textarea
												{...registerFacebook("desc")}
												placeholder="Descripción"
												className={`focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none ${
													errorsFacebook.desc ? "border-red-500" : ""
												}`}
											/>
											<p className="text-xs italic text-red-500">{errorsFacebook.desc?.message}</p>
										</div>
										<div className="mb-8">
											<label className="mb-2 block text-sm font-bold text-gray-700" htmlFor="isCompany">
												Propiedad
											</label>
											<div className="flex">
												<div className="relative inline-block w-64">
													<select
														{...registerFacebook("isCompany")}
														onChange={handleChangeFace}
														className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
													>
														<option value={true}>Compañia</option>
														<option value={false}>Cliente</option>
													</select>
													<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
														<svg className="h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
															<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
														</svg>
													</div>
												</div>
												{clients.length && addCard.clientsActiveFace && (
													<div className="relative ml-3 inline-block w-64">
														<select
															{...registerFacebook("client")}
															className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
														>
															{clients.map((item, index) => (
																<option key={index} value={item.id}>
																	{item.name}
																</option>
															))}
														</select>
														<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
															<svg
																className="h-4 w-4 fill-current"
																xmlns="http://www.w3.org/2000/svg"
																viewBox="0 0 20 20"
															>
																<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
															</svg>
														</div>
													</div>
												)}
											</div>
										</div>
										<div className="mb-8 flex">
											<div className="mr-4 w-1/2">
												<label className="mb-2 block text-sm font-bold text-gray-700" htmlFor="cardType">
													Tipo de Tarjeta
												</label>
												<div className="relative inline-block w-full">
													<select
														{...registerFacebook("cardType")}
														className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
													>
														<option value="0">Desconocido</option>
														<option value="1">Visa</option>
														<option value="2">Mastercard</option>
														<option value="3">American Express</option>
													</select>
													<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
														<svg className="h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
															<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
														</svg>
													</div>
												</div>
											</div>
											<div className="w-1/2">
												<label className="mb-2 block text-sm font-bold text-gray-700" htmlFor="cardType">
													Banco Emisor
												</label>
												<div className="relative inline-block w-full">
													<select
														{...registerFacebook("cardBank")}
														className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
													>
														<option value="0">Desconocido</option>
														<option value="1">CapitalOne</option>
														<option value="2">BankOfAmerica</option>
													</select>
													<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
														<svg className="h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
															<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
														</svg>
													</div>
												</div>
											</div>
										</div>
										<div className="mb-8">
											<label className="mb-2 block text-sm font-bold text-gray-700" htmlFor="state">
												Estado
											</label>
											<div className="relative inline-block w-64">
												<select
													{...registerFacebook("state")}
													className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
												>
													<option value="1">Activo</option>
													<option value="0">Inactivo</option>
												</select>
												<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
													<svg className="h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
														<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
													</svg>
												</div>
											</div>
										</div>
										<div className="flex w-64 items-center justify-between">
											<button
												disabled={isSubmittingFacebook}
												className="focus:shadow-outline flex w-full items-center justify-center rounded bg-blue-500 px-4 py-2 font-bold uppercase text-white hover:bg-blue-700 focus:outline-none"
											>
												{isSubmitting && <LoadingButtons />}
												Adicionar
											</button>
											<button
												onClick={() => disableCard("face")}
												className="focus:shadow-outline ml-3 flex w-full items-center justify-center rounded bg-slate-400 px-4 py-2 font-bold uppercase text-white hover:bg-slate-500 focus:outline-none"
											>
												Cancelar
											</button>
										</div>
									</form>
								</div>
							)}
							{addCard.showGoogle && (
								<div className="ml-3 w-96 rounded border p-3">
									<div className="mb-6 text-xl uppercase">Nueva Tarjeta Google</div>
									<form onSubmit={handleSubmitGoogle(onSubmitCard)} className="max-w-sm">
										<div className="mb-5">
											<label className="mb-2 block text-sm font-bold text-gray-700" htmlFor="number">
												Número
											</label>
											<input
												type="text"
												maxLength={4}
												{...registerGoogle("number")}
												placeholder="Número"
												className={`focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none ${
													errorsGoogle.number ? "border-red-500" : ""
												}`}
											/>
											<p className="text-xs italic text-red-500">{errorsGoogle.number?.message}</p>
										</div>
										<div className="mb-5">
											<label className="mb-2 block text-sm font-bold text-gray-700" htmlFor="desc">
												Descripción
											</label>
											<textarea
												{...registerGoogle("desc")}
												placeholder="Descripción"
												className={`focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none ${
													errorsGoogle.desc ? "border-red-500" : ""
												}`}
											/>
											<p className="text-xs italic text-red-500">{errorsGoogle.desc?.message}</p>
										</div>
										<div className="mb-8">
											<label className="mb-2 block text-sm font-bold text-gray-700" htmlFor="isCompany">
												Propiedad
											</label>
											<div className="flex">
												<div className="relative inline-block w-64">
													<select
														{...registerGoogle("isCompany")}
														onChange={handleChangeGoogle}
														className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
													>
														<option value={true}>Compañia</option>
														<option value={false}>Cliente</option>
													</select>
													<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
														<svg className="h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
															<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
														</svg>
													</div>
												</div>
												{clients.length && addCard.clientsActiveGoogle && (
													<div className="relative ml-3 inline-block w-64">
														<select
															{...registerGoogle("client")}
															className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
														>
															{clients.map((item, index) => (
																<option key={index} value={item.id}>
																	{item.name}
																</option>
															))}
														</select>
														<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
															<svg
																className="h-4 w-4 fill-current"
																xmlns="http://www.w3.org/2000/svg"
																viewBox="0 0 20 20"
															>
																<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
															</svg>
														</div>
													</div>
												)}
											</div>
										</div>
										<div className="mb-8 flex">
											<div className="mr-4 w-1/2">
												<label className="mb-2 block text-sm font-bold text-gray-700" htmlFor="cardType">
													Tipo de Tarjeta
												</label>
												<div className="relative inline-block w-full">
													<select
														{...registerGoogle("cardType")}
														className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
													>
														<option value="0">Desconocido</option>
														<option value="1">Visa</option>
														<option value="2">Mastercard</option>
														<option value="3">American Express</option>
													</select>
													<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
														<svg className="h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
															<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
														</svg>
													</div>
												</div>
											</div>
											<div className="w-1/2">
												<label className="mb-2 block text-sm font-bold text-gray-700" htmlFor="cardType">
													Banco Emisor
												</label>
												<div className="relative inline-block w-full">
													<select
														{...registerGoogle("cardBank")}
														className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
													>
														<option value="0">Desconocido</option>
														<option value="1">CapitalOne</option>
														<option value="2">BankOfAmerica</option>
													</select>
													<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
														<svg className="h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
															<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
														</svg>
													</div>
												</div>
											</div>
										</div>
										<div className="mb-8">
											<label className="mb-2 block text-sm font-bold text-gray-700" htmlFor="state">
												Estado
											</label>
											<div className="relative inline-block w-64">
												<select
													{...registerGoogle("state")}
													className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
												>
													<option value="1">Activo</option>
													<option value="0">Inactivo</option>
												</select>
												<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
													<svg className="h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
														<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
													</svg>
												</div>
											</div>
										</div>
										<div className="flex w-64 items-center justify-between">
											<button
												disabled={isSubmittingGoogle}
												className="focus:shadow-outline flex w-full items-center justify-center rounded bg-blue-500 px-4 py-2 font-bold uppercase text-white hover:bg-blue-700 focus:outline-none"
											>
												{isSubmitting && <LoadingButtons />}
												Adicionar
											</button>
											<button
												onClick={() => disableCard("google")}
												className="focus:shadow-outline ml-3 flex w-full items-center justify-center rounded bg-slate-400 px-4 py-2 font-bold uppercase text-white hover:bg-slate-500 focus:outline-none"
											>
												Cancelar
											</button>
										</div>
									</form>
								</div>
							)}
						</div>
					</>
				)}
				{(clients.error || cards.error) && <p>No fue posible cargar los datos.</p>}
			</div>
		</div>
	);
}
