import {LoadingButtons} from "_components";

//type "delete" - "active"

export const RemoveComponent = ({typeModal = "delete", element, setModalOn, setIsSubmittingRemove, isSubmittingRemove}) => {
	const handleOKClick = () => {
		setModalOn(false);
	};

	const onSubmit = event => {
		setIsSubmittingRemove(true);
		event.preventDefault();
	};

	return (
		<div className="fixed inset-0 z-50 bg-black bg-opacity-75 transition-opacity" onClick={handleOKClick}>
			<div className="flex h-screen items-center justify-center ">
				<div
					className="relative mx-3 w-full max-w-lg flex-col justify-center rounded-xl border-4 border-sky-500 bg-white px-6 py-6 md:mx-0 md:px-8"
					onClick={e => e.stopPropagation()}
				>
					<div className="modal-close z-50 cursor-pointer" onClick={handleOKClick}>
						<svg
							className="absolute right-2 top-2 fill-current"
							xmlns="http://www.w3.org/2000/svg"
							width="18"
							height="18"
							viewBox="0 0 18 18"
						>
							<path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
						</svg>
					</div>
					<form onSubmit={onSubmit}>
						<div className="mb-5 flex text-center text-xl font-bold uppercase md:text-2xl">
							{typeModal === "delete" ? "Eliminar" : "Activar"}
						</div>
						<div>
							¿Está seguro que de desea {typeModal === "delete" ? "eliminar" : "activar"} {element.entity}{" "}
							<strong>{element.title}</strong>?
						</div>
						<div className="buttons mt-6 flex">
							<button
								disabled={isSubmittingRemove}
								className="focus:shadow-outline flex w-full items-center justify-center rounded bg-blue-500 px-4 py-2 font-bold uppercase text-white hover:bg-blue-700 focus:outline-none"
							>
								{isSubmittingRemove && <LoadingButtons />}
								Aceptar
							</button>
							<button
								onClick={handleOKClick}
								className="focus:shadow-outline ml-3 flex w-full items-center justify-center rounded bg-slate-400 px-4 py-2 font-bold uppercase text-white hover:bg-slate-500 focus:outline-none"
							>
								Cancelar
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};
