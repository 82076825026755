import {useEffect} from "react";

export {Header};

function Header() {
	function openSidebar() {
		document.querySelector(".sidebar").classList.toggle("hidden");
		document.querySelector(".margin-move").classList.toggle("ml-[300px]");
	}

	useEffect(() => {
		document.querySelector(".sidebar").classList.remove("hidden");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="flex items-center bg-slate-200 px-2 py-1">
			<span className="left-4 top-5 z-10 mr-2 flex cursor-pointer text-4xl" onClick={openSidebar}>
				<ion-icon name="menu-outline"></ion-icon>
			</span>
			<span>Home</span>
		</div>
	);
}
