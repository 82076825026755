import {useEffect, useState, useMemo} from "react";
import {useSelector, useDispatch} from "react-redux";
import {servicesActions} from "_store";
import DataTable from "react-data-table-component";
import {FilterComponent, Header} from "_components";
import {StateEnum, CardTypeData} from "utils";

export function ServicesListRemoved() {
	const dispatch = useDispatch();
	const {servicesRemoved} = useSelector(x => x.services);

	const [servicesState, setServicesState] = useState([]);
	const [pending, setPending] = useState(true);

	const [filterText, setFilterText] = useState("");
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

	const columns = [
		{
			name: "Servicio",
			selector: row => row.title,
			sortable: true,
		},
		{
			name: "Tarjeta",
			width: "250px",
			selector: row => row.cardId,
			cell: row => <div>{`${row.cardNumber}(${CardTypeData(row.cardType)}) - ${row.cardCompany ? "Compañía" : "Cliente"}`}</div>,
			sortable: true,
		},
		{
			name: "Modo de Pago",
			selector: row => row.facturationMode,
			sortable: true,
		},
		{
			name: "Precio",
			selector: row => row.price,
			sortable: true,
		},
		{
			name: "Inicio",
			selector: row => row.serviceCreationDate,
			sortable: true,
		},
		{
			name: "Vencimiento",
			selector: row => row.serviceExpirationDate,
			sortable: true,
		},
		{
			name: "Estado",
			selector: row => row.state,
			cell: row => (
				<>
					{row.state === StateEnum.Eliminado && (
						<span className="rounded-full bg-orange-500 px-1.5 py-0.5 text-xs text-white">Eliminado</span>
					)}
				</>
			),
			sortable: true,
		},
		{
			name: "Acciones",
			cell: () => <></>,
		},
	];

	//Load data
	useEffect(() => {
		dispatch(servicesActions.getAllRemoved());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	//Loading List
	useEffect(() => {
		if (servicesRemoved.length || servicesRemoved.error || servicesRemoved.length === 0) {
			setServicesState(servicesRemoved);
			setPending(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [servicesRemoved]);

	function replacer(key, value) {
		if (key === "id") return undefined;
		else if (key === "description") return undefined;
		else return value;
	}
	var filteredItems = [];
	if (servicesState.length) {
		filteredItems = servicesState.filter(item => JSON.stringify(item, replacer).toLowerCase().indexOf(filterText.toLowerCase()) !== -1);
	}

	const subHeaderComponentMemo = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText("");
			}
		};

		return (
			<FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} placeholder={"Filtrar"} />
		);
	}, [filterText, resetPaginationToggle]);

	const actionsMemo = useMemo(() => {
		return <></>;
	}, [servicesRemoved]);

	return (
		<div className="margin-move ml-[300px] p-3">
			<Header />
			<DataTable
				title="Lista de Servicios Externos Eliminados"
				columns={columns}
				data={filteredItems}
				expandableRows={true}
				expandableRowsComponent={({data}) => <div className="p-3">{data.description}</div>}
				pagination
				paginationResetDefaultPage={resetPaginationToggle}
				subHeader
				subHeaderComponent={subHeaderComponentMemo}
				persistTableHead
				highlightOnHover
				progressPending={pending}
				actions={actionsMemo}
			/>
		</div>
	);
}
