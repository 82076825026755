import {useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useForm, Controller} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {useSelector, useDispatch} from "react-redux";
import {toast} from "react-toastify";

import {cardActions, advActions} from "_store";
import {Header, LoadingButtons} from "_components";

import Select from "react-select";
import {CardData, CardTypeData} from "utils";

export function ChangeCardGoogle() {
	const navigate = useNavigate();
	const {id} = useParams();
	const dispatch = useDispatch();
	const {cards} = useSelector(x => x.cards);
	const {adv, advChange} = useSelector(x => x.advs);

	const [submitted, setSubmitted] = useState(false);
	const [selectedCards, setselectedCards] = useState(null);

	// form validation rules
	const validationSchema = Yup.object().shape({
		observations: Yup.string().required("This field is required"),
		card: Yup.object().shape({
			value: Yup.string().required("This field is required"),
		}),
	});
	const formOptions = {resolver: yupResolver(validationSchema)};

	// get functions to build form with useForm() hook
	const {register, handleSubmit, formState, control} = useForm(formOptions);
	const {errors, isSubmitting} = formState;

	function onSubmit(data) {
		setSubmitted(true);
		data.card = parseInt(data.card.value);
		data.advertisingCard = id;
		data.source = 0;
		return dispatch(advActions.change({data}));
	}

	useEffect(() => {
		if (!isSubmitting && submitted && !advChange.error) {
			toast.success("Datos adicionados correctamente.");
			setSubmitted(false);
			navigate("/advertising/list/");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSubmitting]);

	useEffect(() => {
		dispatch(cardActions.getAll());
		dispatch(advActions.get(id));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (cards.length) {
			const options = cards.map(card => ({
				label: `${card.number}${CardData(card.cardType, card.cardBank)} - ${card.isCompany ? "Compañía" : "Cliente"}`,
				value: card.id,
			}));
			options.unshift({
				label: "No tiene",
				value: 0,
			});
			setselectedCards(options);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cards]);

	return (
		<div className="margin-move ml-[300px] p-3">
			<Header />
			<div className="bg-white px-4 pb-5 pt-3">
				<div className="mb-6 text-xl">Cambiar Tarjeta Google</div>
				<form onSubmit={handleSubmit(onSubmit)} className="max-w-sm">
					<div className="mb-5">
						<span className="mb-2 block text-sm font-bold text-gray-700">Cliente</span>
						<div>{adv.client}</div>
					</div>
					<div className="mb-5">
						<span className="mb-2 block text-sm font-bold text-gray-700">Tarjeta Anterior</span>
						<div>
							{adv.cards?.map(
								(item, index) =>
									item.state &&
									item.source === 0 && (
										<div key={index}>
											{item.number === "0" ? (
												<strong>SIN TARJETA</strong>
											) : (
												`${item.number}${CardData(item.type, item.bank)} - ${item.isCompany ? "Compañía" : "Cliente"}`
											)}
										</div>
									),
							)}
							{adv.cards?.length === 0 && <strong>SIN TARJETA</strong>}
						</div>
					</div>
					<div className="mb-5">
						<label className="mb-2 block text-sm font-bold text-gray-700" htmlFor="selectCards">
							Tarjeta Nueva
						</label>
						<Controller name="card" control={control} render={({field}) => <Select {...field} options={selectedCards} />} />
					</div>
					<div className="mb-5">
						<label className="mb-2 block text-sm font-bold text-gray-700" htmlFor="observations">
							Observaciones Cambio
						</label>
						<textarea
							{...register("observations")}
							placeholder="Observaciones"
							className={`focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none ${
								errors.desc ? "border-red-500" : ""
							}`}
						/>
						<p className="text-xs italic text-red-500">{errors.desc?.message}</p>
					</div>
					<div className="flex w-64 items-center justify-between">
						<button
							disabled={isSubmitting}
							className="focus:shadow-outline flex w-full items-center justify-center rounded bg-blue-500 px-4 py-2 font-bold uppercase text-white hover:bg-blue-700 focus:outline-none"
						>
							{isSubmitting && <LoadingButtons />}
							Guardar
						</button>
						<Link
							to={"/advertising/list/"}
							className="focus:shadow-outline ml-3 flex w-full items-center justify-center rounded bg-slate-400 px-4 py-2 font-bold uppercase text-white hover:bg-slate-500 focus:outline-none"
						>
							Cancelar
						</Link>
					</div>
				</form>
			</div>
		</div>
	);
}
