import {useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {useSelector, useDispatch} from "react-redux";
import {toast} from "react-toastify";
import Select from "react-select";

import {userActions} from "_store";
import {Header, LoadingButtons} from "_components";

export function EditUser() {
	const navigate = useNavigate();
	const {id} = useParams();
	const dispatch = useDispatch();
	const {user, userEdit} = useSelector(x => x.users);
	const [submitted, setSubmitted] = useState(false);

	const [selectedRoles, setSelectedRoles] = useState([]);
	const selectRoles = [
		{
			label: "Admin",
			value: 1,
		},
		{
			label: "Services",
			value: 2,
		},
		{
			label: "Domains",
			value: 3,
		},
		{
			label: "Advertisings",
			value: 4,
		},
	];

	// form validation rules Service
	const validationSchema = Yup.object().shape({
		name: Yup.string().required("This field is required"),
		username: Yup.string().required("This field is required"),
		state: Yup.number().required("This field is required"),
	});

	const formOptions = {resolver: yupResolver(validationSchema)};

	// get functions to build form with useForm() hook
	const {register, handleSubmit, formState, reset, control} = useForm(formOptions);
	const {errors, isSubmitting} = formState;

	// Function submit User (EDIT)
	function onSubmit(data) {
		setSubmitted(true);
		data.rolesReq = [];
		selectedRoles.forEach(selectedRol => data.rolesReq.push(selectedRol.value));
		return dispatch(userActions.edit({data}));
	}
	useEffect(() => {
		dispatch(userActions.get(id));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
		// reset form with card data
		reset(user);

		if (user && !user.loading && !user.error && user.roles) {
			setSelectedRoles([]);
			user.roles.forEach(userRol => {
				const element = selectRoles.find(c => c.value === userRol.id);
				setSelectedRoles(selectedRoles => [...selectedRoles, element]);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);
	useEffect(() => {
		if (!isSubmitting && submitted && !userEdit.error) {
			toast.success("Datos editados correctamente.");
			reset();
			setSubmitted(false);
			navigate("/users/list");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSubmitting]);

	return (
		<div className="margin-move ml-[300px] p-3">
			<Header />
			{(user.error || userEdit?.error) && (
				<div className="relative mb-3 rounded border border-red-400 bg-red-100 px-4 py-3 text-red-700" role="alert">
					{user.error && <label>ERROR: {user.error.message}</label>}
					{userEdit.error && <label>ERROR: {userEdit.error.message}</label>}
				</div>
			)}
			<div className="bg-white px-4 pb-5 pt-3">
				<div className="mb-6 text-xl">Editar Usuario</div>
				{user.loading && <div className="">Cargando...</div>}
				{user && !user.loading && !user.error && (
					<>
						<div className="flex items-start">
							<div className="w-96 rounded border p-3">
								<div className="mb-6 text-xl uppercase">Editar Usuario</div>
								<form onSubmit={handleSubmit(onSubmit)} className="max-w-sm">
									<div className="mb-5">
										<div className="mb-2">
											<label className="mb-2 block text-sm font-bold text-gray-700" htmlFor="title">
												Nombre
											</label>
											<input
												{...register("name")}
												placeholder="Nombre"
												className={`focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none ${
													errors.name ? "border-red-500" : ""
												}`}
											/>
											<p className="text-xs italic text-red-500">{errors.name?.message}</p>
										</div>
									</div>

									<div className="mb-5">
										<div className="mb-2">
											<label className="mb-2 block text-sm font-bold text-gray-700" htmlFor="title">
												Usuario
											</label>
											<input
												{...register("username")}
												placeholder="Usuario"
												className={`focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none ${
													errors.name ? "border-red-500" : ""
												}`}
											/>
											<p className="text-xs italic text-red-500">{errors.username?.message}</p>
										</div>
									</div>

									<div className="mb-5">
										<div className="mb-2">
											<label className="mb-2 block text-sm font-bold text-gray-700" htmlFor="state">
												Asignar Roles
											</label>
											<Controller
												name="rolesRequest"
												control={control}
												render={({field}) => (
													<Select
														{...field}
														isMulti
														options={selectRoles}
														value={selectedRoles}
														onChange={selectedOption => {
															setSelectedRoles(selectedOption);
															field.onChange(selectedOption);
														}}
													/>
												)}
											/>
											<div />

											{/* <div className="relative inline-block w-40">
												<select
													{...register("Roles")}
													multiple
													className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
												>
													<option value={1}>Admin</option>
													<option value={2}>Services</option>
													<option value={3}>Domains</option>
													<option value={4}>Advertisings</option>
												</select>
											</div> */}
										</div>
									</div>

									<div className="mb-8 flex">
										<div>
											<label className="mb-2 block text-sm font-bold text-gray-700" htmlFor="state">
												Estado
											</label>
											<div className="relative inline-block w-40">
												<select
													{...register("state")}
													className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
												>
													<option value={1}>Activo</option>
													<option value={0}>Inactivo</option>
												</select>
												<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
													<svg className="h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
														<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
													</svg>
												</div>
											</div>
										</div>
									</div>

									<div className="flex w-64 items-center justify-between">
										<button
											disabled={isSubmitting}
											className="focus:shadow-outline flex w-full items-center justify-center rounded bg-blue-500 px-4 py-2 font-bold uppercase text-white hover:bg-blue-700 focus:outline-none"
										>
											{isSubmitting && <LoadingButtons />}
											Editar
										</button>
										<Link
											to={"/users/list/"}
											onClick={reset}
											className="focus:shadow-outline ml-3 flex w-full items-center justify-center rounded bg-slate-400 px-4 py-2 font-bold uppercase text-white hover:bg-slate-500 focus:outline-none"
										>
											Cancelar
										</Link>
									</div>
								</form>
							</div>
						</div>
					</>
				)}
				{user.error && <p>No fue posible cargar los datos.</p>}
			</div>
		</div>
	);
}
