import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {fetchWrapper} from "_helpers";

// create slice

const name = "clients";
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({name, initialState, extraReducers});

// exports

export const clientActions = {...slice.actions, ...extraActions};
export const clientsReducer = slice.reducer;

// implementation

function createInitialState() {
	return {
		client: {},
		clients: {},
		clientsRemoved: {},
		clientRemove: {},
		clientAdd: {},
		clientEdit: {},
	};
}

function createExtraActions() {
	const baseUrl = `${process.env.REACT_APP_API_URL}/clients`;

	return {
		get: get(),
		getAll: getAll(),
		add: add(),
		edit: edit(),
		remove: remove(),
		getAllRemoved: getAllRemoved(),
	};

	function get() {
		return createAsyncThunk(`${name}/get`, async id => await fetchWrapper.get(`${baseUrl}/${id}`));
	}

	function getAll() {
		return createAsyncThunk(`${name}/getAll`, async () => await fetchWrapper.get(baseUrl));
	}

	function getAllRemoved() {
		return createAsyncThunk(`${name}/getAllRemoved`, async () => await fetchWrapper.get(`${baseUrl}/all-removed`));
	}

	function add() {
		return createAsyncThunk(`${name}/add`, async ({name, desc, state}) => await fetchWrapper.post(`${baseUrl}`, {name, desc, state}));
	}

	function edit() {
		return createAsyncThunk(`${name}/edit`, async data => await fetchWrapper.put(`${baseUrl}`, data.data));
	}

	function remove() {
		return createAsyncThunk(`${name}/delete`, async ({id}) => await fetchWrapper.delete(`${baseUrl}`, {id}));
	}
}

function createExtraReducers() {
	return {
		...getAll(),
		...getAllRemoved(),
		...get(),
		...add(),
		...edit(),
		...remove(),
	};

	function getAll() {
		var {pending, fulfilled, rejected} = extraActions.getAll;
		return {
			[pending]: state => {
				state.clients = {loading: true};
			},
			[fulfilled]: (state, action) => {
				state.clients = action.payload;
			},
			[rejected]: (state, action) => {
				state.clients = {error: action.error};
			},
		};
	}
	function getAllRemoved() {
		var {pending, fulfilled, rejected} = extraActions.getAllRemoved;
		return {
			[pending]: state => {
				state.clientsRemoved = {loading: true};
			},
			[fulfilled]: (state, action) => {
				state.clientsRemoved = action.payload;
			},
			[rejected]: (state, action) => {
				state.clientsRemoved = {error: action.error};
			},
		};
	}
	function get() {
		var {pending, fulfilled, rejected} = extraActions.get;
		return {
			[pending]: state => {
				state.client = {loading: true};
			},
			[fulfilled]: (state, action) => {
				state.client = action.payload;
			},
			[rejected]: (state, action) => {
				state.client = {error: action.error};
			},
		};
	}
	function add() {
		var {pending, fulfilled, rejected} = extraActions.add;
		return {
			[pending]: state => {
				state.clientAdd = {loading: true};
			},
			[fulfilled]: (state, action) => {
				state.clientAdd = action.payload;
			},
			[rejected]: (state, action) => {
				state.clientAdd = {error: action.error};
			},
		};
	}
	function edit() {
		var {pending, fulfilled, rejected} = extraActions.edit;
		return {
			[pending]: state => {
				state.clientEdit = {loading: true};
			},
			[fulfilled]: (state, action) => {
				state.clientEdit = action.payload;
			},
			[rejected]: (state, action) => {
				state.clientEdit = {error: action.error};
			},
		};
	}
	function remove() {
		var {pending, fulfilled, rejected} = extraActions.remove;
		return {
			[pending]: state => {
				state.clientRemove = {loading: true};
			},
			[fulfilled]: (state, action) => {
				state.clientRemove = action.payload;
			},
			[rejected]: (state, action) => {
				state.clientRemove = {error: action.error};
			},
		};
	}
}
