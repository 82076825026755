import {useEffect, useState, useMemo} from "react";
import {useSelector, useDispatch} from "react-redux";
import {cardActions} from "_store";
import DataTable from "react-data-table-component";
import {FilterComponent, ExpandedComponent, ExportButton, Header} from "_components";
import {StateEnum, CardTypeEnum, CardBankEnum, CardTypeData, CardBankData, downloadCSV} from "utils";

export function CardsListRemoved() {
	const dispatch = useDispatch();
	const {cardsRemoved} = useSelector(x => x.cards);

	const [filterText, setFilterText] = useState("");
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
	const [pending, setPending] = useState(true);

	const columns = [
		{
			name: "Dueño",
			selector: row => (row.clientName != null ? row.clientName : "Compañía"),
			sortable: true,
		},
		{
			name: "Propiedad",
			selector: row => row.isCompany,
			cell: row =>
				row.isCompany ? (
					<span className="rounded-full bg-green-500 px-1.5 py-0.5 text-xs text-white">Compañía</span>
				) : (
					<span className="rounded-full bg-orange-500 px-1.5 py-0.5 text-xs text-white">Cliente</span>
				),
			sortable: true,
		},
		{
			name: "Número",
			selector: row => row.number,
			sortable: true,
		},
		{
			name: "Tipo",
			selector: row => row.cardType,
			cell: row => (
				<>
					{row.cardType === CardTypeEnum.Desconocido ? (
						<span className="rounded-full bg-red-500 px-1.5 py-0.5 text-xs text-white">Desconocido</span>
					) : (
						<span className="rounded-full bg-sky-500 px-1.5 py-0.5 text-xs text-white">{CardTypeData(row.cardType)}</span>
					)}
				</>
			),
			sortable: true,
		},
		{
			name: "Banco",
			selector: row => row.cardBank,
			cell: row => (
				<>
					{row.cardBank === CardBankEnum.Desconocido ? (
						<span className="rounded-full bg-red-500 px-1.5 py-0.5 text-xs text-white">Desconocido</span>
					) : (
						<span className="rounded-full bg-sky-500 px-1.5 py-0.5 text-xs text-white">{CardBankData(row.cardBank)}</span>
					)}
				</>
			),
			sortable: true,
		},
		{
			name: "Fecha Ingreso",
			selector: row => row.creationDate,
			sortable: true,
		},
		{
			name: "Estado",
			selector: row => row.state,
			cell: row => (
				<>
					{row.state === StateEnum.Eliminado && (
						<span className="rounded-full bg-orange-500 px-1.5 py-0.5 text-xs text-white">Eliminado</span>
					)}
				</>
			),
			sortable: true,
		},
		{
			name: "Acciones",
			cell: row => <></>,
		},
	];

	//Load data
	useEffect(() => {
		dispatch(cardActions.getAllRemoved());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	//Loading List
	useEffect(() => {
		if (cardsRemoved.length || cardsRemoved.error || cardsRemoved.length === 0) {
			setPending(false);
		}
	}, [cardsRemoved]);

	function replacer(key, value) {
		if (key === "id") return undefined;
		else if (key === "desc") return undefined;
		else return value;
	}
	var filteredItems = [];
	if (cardsRemoved.length) {
		filteredItems = cardsRemoved.filter(item => JSON.stringify(item, replacer).toLowerCase().indexOf(filterText.toLowerCase()) !== -1);
	}

	const subHeaderComponentMemo = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText("");
			}
		};

		return (
			<>
				<FilterComponent
					onFilter={e => setFilterText(e.target.value)}
					onClear={handleClear}
					filterText={filterText}
					placeholder={"Filtrar"}
				/>
			</>
		);
	}, [filterText, resetPaginationToggle]);

	const actionsMemo = useMemo(() => {
		return (
			<>
				<ExportButton onExport={() => downloadCSV(cardsRemoved)} />
			</>
		);
	}, [cardsRemoved]);

	return (
		<div className="margin-move ml-[300px] p-3">
			<Header />
			{cardsRemoved.error && (
				<div className="relative mb-3 rounded border border-red-400 bg-red-100 px-4 py-3 text-red-700" role="alert">
					{cardsRemoved.error && <label>ERROR: {cardsRemoved.error.message}</label>}
				</div>
			)}
			<DataTable
				title="Lista Tarjetas Eliminadas"
				columns={columns}
				data={filteredItems}
				expandableRows={true}
				expandableRowsComponent={ExpandedComponent}
				pagination
				paginationResetDefaultPage={resetPaginationToggle}
				subHeader
				subHeaderComponent={subHeaderComponentMemo}
				persistTableHead
				highlightOnHover
				progressPending={pending}
				actions={actionsMemo}
			/>
		</div>
	);
}
