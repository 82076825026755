import {useEffect, useState, useMemo} from "react";
import {Link} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import {userActions} from "_store";
import DataTable from "react-data-table-component";
import {FilterComponent, ExpandedComponent, Header} from "_components";
import {StateEnum} from "utils";

export function UsersList() {
	const dispatch = useDispatch();
	const {users} = useSelector(x => x.users);

	const [usersState, setUsersState] = useState([]);
	const [filterText, setFilterText] = useState("");
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
	const [pending, setPending] = useState(true);

	const columns = [
		{
			name: "Nombre",
			selector: row => row.name,
		},
		{
			name: "Usuario",
			selector: row => row.username,
		},
		{
			name: "Roles",
			selector: row => row.roles,
			cell: row => (
				<div>
					{row.roles.map((rol, index) => (
						<div key={index} className="">
							{rol.rol}
						</div>
					))}
				</div>
			),
		},
		{
			name: "Estado",
			selector: row => row.stateString,
			cell: row => (
				<>
					{row.state === StateEnum.Activo && <span className="rounded-full bg-sky-500 px-1.5 py-0.5 text-xs text-white">Activo</span>}
					{row.state === StateEnum.Inactivo && <span className="rounded-full bg-red-500 px-1.5 py-0.5 text-xs text-white">Inactivo</span>}
				</>
			),
		},
		{
			name: "Acciones",
			cell: row => (
				<>
					<Link to={"/users/edit/" + row.id} title="Editar" className="text-lg">
						<ion-icon name="create-outline"></ion-icon>
					</Link>
				</>
			),
		},
	];

	//Load data
	useEffect(() => {
		dispatch(userActions.getAll());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	//Loading List
	useEffect(() => {
		if (users.length || users.error || users.length === 0) {
			setUsersState(users);
			setPending(false);
		}
	}, [users]);

	var filteredItems = [];

	function replacer(key, value) {
		if (key === "id") return undefined;
		else if (key === "desc") return undefined;
		else return value;
	}
	if (usersState.length) {
		filteredItems = usersState.filter(item => JSON.stringify(item, replacer).toLowerCase().indexOf(filterText.toLowerCase()) !== -1);
	}

	const subHeaderComponentMemo = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText("");
			}
		};

		return (
			<>
				<FilterComponent
					onFilter={e => setFilterText(e.target.value)}
					onClear={handleClear}
					filterText={filterText}
					placeholder={"Filtrar por Nombre"}
				/>
			</>
		);
	}, [filterText, resetPaginationToggle]);

	const actionsMemo = useMemo(() => {
		return (
			<>
				<Link title="Adicionar Usuario" className="flex items-center justify-center rounded-md bg-blue-600 px-2 text-white" to={"/users/add"}>
					<span className="pr-1">Adicionar</span>
					<ion-icon name="add-circle-outline"></ion-icon>
				</Link>
			</>
		);
	}, [users]);

	return (
		<div className="margin-move ml-[300px] p-3">
			<Header />
			{users.error && (
				<div className="relative mb-3 rounded border border-red-400 bg-red-100 px-4 py-3 text-red-700" role="alert">
					{users.error && <label>ERROR: {users.error.message}</label>}
				</div>
			)}
			<DataTable
				title="Lista Usuarios"
				columns={columns}
				data={filteredItems}
				expandableRows={true}
				expandableRowsComponent={ExpandedComponent}
				pagination
				paginationResetDefaultPage={resetPaginationToggle}
				subHeader
				subHeaderComponent={subHeaderComponentMemo}
				persistTableHead
				highlightOnHover
				progressPending={pending}
				actions={actionsMemo}
			/>
		</div>
	);
}
