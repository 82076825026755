import {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {useSelector, useDispatch} from "react-redux";
import {toast} from "react-toastify";

import {clientActions} from "_store";
import {Header, LoadingButtons} from "_components";

export function AddClient() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const {clientAdd} = useSelector(x => x.clients);

	const [submitted, setSubmitted] = useState(false);

	// form validation rules
	const validationSchema = Yup.object().shape({
		name: Yup.string().required("This field is required"),
		desc: Yup.string().required("This field is required"),
	});
	const formOptions = {resolver: yupResolver(validationSchema)};

	// get functions to build form with useForm() hook
	const {register, handleSubmit, formState, reset} = useForm(formOptions);
	const {errors, isSubmitting} = formState;

	function onSubmit({name, desc, state}) {
		setSubmitted(true);
		return dispatch(clientActions.add({name, desc, state}));
	}

	useEffect(() => {
		if (!isSubmitting && submitted && !clientAdd.error) {
			reset();
			toast.success("Datos adicionados correctamente.");
			setSubmitted(false);
			navigate("/clients/list");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSubmitting]);

	return (
		<div className="margin-move ml-[300px] p-3">
			<Header />
			{clientAdd.error && (
				<div className="relative mb-3 rounded border border-red-400 bg-red-100 px-4 py-3 text-red-700" role="alert">
					ERROR: {clientAdd.error.message}
				</div>
			)}
			<div className="bg-white px-4 pb-5 pt-3">
				<div className="mb-6 text-xl">Agregar Cliente</div>
				<form onSubmit={handleSubmit(onSubmit)} className="max-w-sm">
					<div className="mb-5">
						<label className="mb-2 block text-sm font-bold text-gray-700" htmlFor="name">
							Nombre
						</label>
						<input
							type="text"
							{...register("name")}
							placeholder="Nombre"
							className={`focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none ${
								errors.name ? "border-red-500" : ""
							}`}
						/>
						<p className="text-xs italic text-red-500">{errors.name?.message}</p>
					</div>
					<div className="mb-5">
						<label className="mb-2 block text-sm font-bold text-gray-700" htmlFor="desc">
							Descripción
						</label>
						<textarea
							{...register("desc")}
							placeholder="Descripción"
							className={`focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none ${
								errors.desc ? "border-red-500" : ""
							}`}
						/>
						<p className="text-xs italic text-red-500">{errors.desc?.message}</p>
					</div>
					<div className="mb-8">
						<label className="mb-2 block text-sm font-bold text-gray-700" htmlFor="state">
							Estado
						</label>
						<div className="relative inline-block w-64">
							<select
								{...register("state")}
								className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
							>
								<option value="1">Activo</option>
								<option value="0">Inactivo</option>
							</select>
							<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
								<svg className="h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
									<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
								</svg>
							</div>
						</div>
					</div>
					<div className="flex w-64 items-center justify-between">
						<button
							disabled={isSubmitting}
							className="focus:shadow-outline flex w-full items-center justify-center rounded bg-blue-500 px-4 py-2 font-bold uppercase text-white hover:bg-blue-700 focus:outline-none"
						>
							{isSubmitting && <LoadingButtons />}
							Adicionar
						</button>
						<Link
							to={"/clients/list"}
							className="focus:shadow-outline ml-3 flex w-full items-center justify-center rounded bg-slate-400 px-4 py-2 font-bold uppercase text-white hover:bg-slate-500 focus:outline-none"
						>
							Cancelar
						</Link>
					</div>
				</form>
			</div>
		</div>
	);
}
