import {useEffect, useState, useMemo} from "react";
import {Link} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import {servicesActions} from "_store";
import DataTable from "react-data-table-component";
import {FilterComponent, ExportButton, RemoveComponent, Header} from "_components";
import {StateEnum, downloadCSV, CardData} from "utils";
import {toast} from "react-toastify";

export function ServicesList() {
	const dispatch = useDispatch();
	const {services, serviceRemove} = useSelector(x => x.services);

	const [servicesState, setServicesState] = useState([]);
	const [pending, setPending] = useState(true);

	const [filterText, setFilterText] = useState("");
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

	const [modalRemoveOn, setModalRemoveOn] = useState(false);
	const [selectedRemove, setSelectedRemove] = useState();
	const [isSubmittingRemove, setIsSubmittingRemove] = useState(false);

	const columns = [
		{
			name: "Servicio",
			selector: row => row.title,
			sortable: true,
		},
		{
			name: "Tarjeta",
			width: "250px",
			selector: row => row.cardId,
			//${item.number}${CardData(item.type, item.bank)} - ${item.isCompany ? "Compañía" : "Cliente"}
			cell: row => <div>{`${row.cardNumber}${CardData(row.cardType, row.cardBank)} - ${row.cardCompany ? "Compañía" : "Cliente"}`}</div>,
			sortable: true,
		},
		{
			name: "Modo de Pago",
			selector: row => row.facturationMode,
			sortable: true,
		},
		{
			name: "Precio",
			selector: row => row.price,
			sortable: true,
		},
		{
			name: "Inicio",
			selector: row => row.serviceCreationDate,
			sortable: true,
		},
		{
			name: "Vencimiento",
			selector: row => row.serviceExpirationDate,
			sortable: true,
		},
		{
			name: "Estado",
			selector: row => row.state,
			cell: row => (
				<>
					{row.state === StateEnum.Activo && <span className="rounded-full bg-sky-500 px-1.5 py-0.5 text-xs text-white">Activo</span>}
					{row.state === StateEnum.Inactivo && <span className="rounded-full bg-red-500 px-1.5 py-0.5 text-xs text-white">Inactivo</span>}
				</>
			),
			sortable: true,
		},
		{
			name: "Acciones",
			cell: row => (
				<>
					<Link to={"/ext-services/edit/" + row.id} title="Ver Detalles" className="mx-1 text-lg">
						<ion-icon name="create-outline"></ion-icon>
					</Link>
					<button onClick={() => clickedRemove(row.id, row.client, "el servicio seleccionado")} title="Eliminar" className="mx-1 text-lg">
						<ion-icon name="trash-bin-outline"></ion-icon>
					</button>
				</>
			),
		},
	];

	//Load data
	useEffect(() => {
		dispatch(servicesActions.getAll());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	//Loading List
	useEffect(() => {
		if (services.length || services.error || services.length === 0) {
			setServicesState(services);
			setPending(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [services]);

	useEffect(() => {
		if (isSubmittingRemove) {
			dispatch(servicesActions.remove({id: selectedRemove.id}));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSubmittingRemove]);

	useEffect(() => {
		if (isSubmittingRemove && !serviceRemove.loading && !serviceRemove.error) {
			setIsSubmittingRemove(false);
			setModalRemoveOn(false);
			setServicesState(
				servicesState.filter(function (item) {
					return item.id !== selectedRemove.id;
				}),
			);
			toast.success("Datos eliminados correctamente.");
		} else if (serviceRemove.error) {
			setIsSubmittingRemove(false);
			setModalRemoveOn(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [serviceRemove]);

	const clickedRemove = (id, title, entity) => {
		setSelectedRemove({
			id,
			title,
			entity,
		});
		setModalRemoveOn(true);
	};

	function replacer(key, value) {
		if (key === "id") return undefined;
		else if (key === "description") return undefined;
		else return value;
	}
	var filteredItems = [];
	if (servicesState.length) {
		filteredItems = servicesState.filter(item => JSON.stringify(item, replacer).toLowerCase().indexOf(filterText.toLowerCase()) !== -1);
	}

	const subHeaderComponentMemo = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText("");
			}
		};

		return (
			<FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} placeholder={"Filtrar"} />
		);
	}, [filterText, resetPaginationToggle]);

	const actionsMemo = useMemo(() => {
		return (
			<>
				<Link
					title="Adicionar Servicio"
					className="flex items-center justify-center rounded-md bg-blue-600 px-2 text-white"
					to="/ext-services/add"
				>
					<span className="pr-1">Adicionar</span>
					<ion-icon name="add-circle-outline"></ion-icon>
				</Link>
				<Link
					title="Lista Tarjetas"
					className="flex items-center justify-center rounded-md bg-blue-600 px-2 py-1 text-white"
					to={"/cards/list"}
				>
					<ion-icon name="card-outline"></ion-icon>
				</Link>
				<Link
					title="Lista Servicios Eliminados"
					className="flex items-center justify-center rounded-md bg-blue-600 px-2 py-1 text-white"
					to={"/ext-services/list-removed"}
				>
					<ion-icon name="trash-outline"></ion-icon>
				</Link>
				{services.length >= 0 && <ExportButton onExport={() => downloadCSV(services)} />}
			</>
		);
	}, [services]);

	return (
		<div className="margin-move ml-[300px] p-3">
			<Header />
			<DataTable
				title="Lista de Servicios Externos"
				columns={columns}
				data={filteredItems}
				expandableRows={true}
				expandableRowsComponent={({data}) => <div className="p-3">{data.description}</div>}
				pagination
				paginationResetDefaultPage={resetPaginationToggle}
				subHeader
				subHeaderComponent={subHeaderComponentMemo}
				persistTableHead
				highlightOnHover
				progressPending={pending}
				actions={actionsMemo}
			/>
			{modalRemoveOn && (
				<RemoveComponent
					element={selectedRemove}
					setModalOn={setModalRemoveOn}
					isSubmittingRemove={isSubmittingRemove}
					setIsSubmittingRemove={setIsSubmittingRemove}
				></RemoveComponent>
			)}
		</div>
	);
}
