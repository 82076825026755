import {configureStore} from "@reduxjs/toolkit";

import {authReducer} from "./auth.slice";
import {usersReducer} from "./users.slice";
import {clientsReducer} from "./clients.slice";
import {cardsReducer} from "./cards.slice";
import {advsReducer} from "./advs.slice";
import {webDomainReducer} from "./web.slice";
import {servicesReducer} from "./services.slice";

export * from "./auth.slice";
export * from "./users.slice";
export * from "./clients.slice";
export * from "./cards.slice";
export * from "./advs.slice";
export * from "./web.slice";
export * from "./services.slice";

export const store = configureStore({
	reducer: {
		auth: authReducer,
		users: usersReducer,
		clients: clientsReducer,
		cards: cardsReducer,
		advs: advsReducer,
		webDomains: webDomainReducer,
		services: servicesReducer,
	},
});
