import {useEffect, useState, useMemo} from "react";
import {useSelector, useDispatch} from "react-redux";
import {advActions} from "_store";
import DataTable from "react-data-table-component";
import {FilterComponent, ExpandedComponent, ExportButton, ModalAdv, Header, RemoveComponent} from "_components";
import {StateEnumUpd, downloadCSV, CardData} from "utils";
import {toast} from "react-toastify";

export function AdvListRemoved() {
	const dispatch = useDispatch();
	const {advsRemoved, adv, advActive} = useSelector(x => x.advs);

	const [advsState, setAdvsState] = useState([]);
	const [filterText, setFilterText] = useState("");
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
	const [pending, setPending] = useState(true);
	const [modalOn, setModalOn] = useState(false);

	const [modalActiveOn, setModalActiveOn] = useState(false);
	const [selectedActive, setSelectedActive] = useState();
	const [isSubmittingActive, setIsSubmittingActive] = useState(false);

	const [cardsFaceb, setCardsFaceb] = useState();
	const [cardsGoogle, setCardsGoogle] = useState();

	const columns = [
		{
			name: "Cliente",
			selector: row => row.client,
			sortable: true,
		},
		{
			name: "Tarjeta Google",
			selector: row => row.cards.map(item => (item.source === 0 ? item.number + " - " + (item.isCompany ? "Compañía" : "Cliente") : "")),
			width: "250px",
			cell: row => (
				<div>
					{row.cards.map((item, index) => (
						<div key={index}>
							{item.source === 0 && (
								<div>
									{item.number === null ? (
										<strong>No Tiene</strong>
									) : (
										`${item.number}${CardData(item.type, item.bank)} - ${item.isCompany ? "Compañía" : "Cliente"}`
									)}
								</div>
							)}
						</div>
					))}
				</div>
			),
			sortable: true,
		},
		{
			name: "Tarjeta Facebook",
			selector: row => row.cards.map(item => (item.source === 1 ? item.number + "-" + (item.isCompany ? "Compañía" : "Cliente") : "")),
			width: "250px",
			cell: row => (
				<div>
					{row.cards.map((item, index) => (
						<div key={index}>
							{item.source === 1 && (
								<div>
									{item.number === null ? (
										<strong>No Tiene</strong>
									) : (
										`${item.number}${CardData(item.type, item.bank)} - ${item.isCompany ? "Compañía" : "Cliente"}`
									)}
								</div>
							)}
						</div>
					))}
				</div>
			),
			sortable: true,
		},
		{
			name: "Fecha Ingreso",
			selector: row => row.creationDate,
			sortable: true,
		},
		{
			name: "Estado",
			selector: row => row.state,
			cell: row => (
				<>
					{row.state === StateEnumUpd.Eliminado && (
						<span className="rounded-full bg-orange-500 px-1.5 py-0.5 text-xs text-white">Eliminado</span>
					)}
				</>
			),
			sortable: true,
		},
		{
			name: "Acciones",
			cell: row => (
				<>
					<button onClick={() => clicked(row.id)} title="Ver Detalles" className="mx-1 text-lg">
						<ion-icon name="eye-outline"></ion-icon>
					</button>
					<button
						onClick={() => clickedActive(row.id, row.client, "la publicidad-source del cliente")}
						title="Activar"
						className="mx-1 text-lg"
					>
						<ion-icon name="log-out-outline"></ion-icon>
					</button>
				</>
			),
		},
	];

	//Load data
	useEffect(() => {
		dispatch(advActions.getAllRemoved());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	//Loading List
	useEffect(() => {
		if (advsRemoved.length || advsRemoved.error || advsRemoved.length === 0) {
			setAdvsState(advsRemoved);
			setPending(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [advsRemoved]);

	//Load List Face & Google
	useEffect(() => {
		if (adv && !adv.loading && !adv.error) {
			fetchData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [adv]);

	useEffect(() => {
		if (isSubmittingActive) {
			dispatch(advActions.active({id: selectedActive.id}));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSubmittingActive]);

	useEffect(() => {
		if (isSubmittingActive && !advActive.loading && !advActive.error) {
			setIsSubmittingActive(false);
			setModalActiveOn(false);
			setAdvsState(
				advsState.filter(function (item) {
					return item.id !== selectedActive.id;
				}),
			);
			toast.success("Datos activados correctamente.");
		} else if (advActive.error) {
			setIsSubmittingActive(false);
			setModalActiveOn(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [advActive]);

	const clickedActive = (id, title, entity) => {
		setSelectedActive({
			id,
			title,
			entity,
		});
		setModalActiveOn(true);
	};
	const clicked = id => {
		dispatch(advActions.get(id));
		setModalOn(true);
	};
	const handleOKClick = () => {
		setModalOn(false);
	};

	const fetchData = () => {
		let tempGoogle;
		tempGoogle = [];
		let tempFacebook;
		tempFacebook = [];

		adv.cards?.forEach(item => {
			if (item.source === 0) {
				tempGoogle.push(item);
			} else tempFacebook.push(item);
		});
		setCardsGoogle(tempGoogle);
		setCardsFaceb(tempFacebook);
	};

	function replacer(key, value) {
		if (key === "id") return undefined;
		else if (key === "desc") return undefined;
		else return value;
	}
	var filteredItems = [];
	if (advsState.length) {
		filteredItems = advsState.filter(item => JSON.stringify(item, replacer).toLowerCase().indexOf(filterText.toLowerCase()) !== -1);
	}

	const subHeaderComponentMemo = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText("");
			}
		};

		return (
			<FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} placeholder={"Filtrar"} />
		);
	}, [filterText, resetPaginationToggle]);

	const actionsMemo = useMemo(() => {
		return (
			<>
				<ExportButton onExport={() => downloadCSV(advsRemoved)} />
			</>
		);
	}, [advsRemoved]);

	return (
		<div className="margin-move ml-[300px] p-3">
			<Header />
			{advsRemoved.error && (
				<div className="relative mb-3 rounded border border-red-400 bg-red-100 px-4 py-3 text-red-700" role="alert">
					{advsRemoved.error && <label>ERROR: {advsRemoved.error.message}</label>}
				</div>
			)}
			<DataTable
				title="Lista Publicidad Sources Eliminados"
				columns={columns}
				data={filteredItems}
				expandableRows={true}
				expandableRowsComponent={ExpandedComponent}
				pagination
				paginationResetDefaultPage={resetPaginationToggle}
				subHeader
				subHeaderComponent={subHeaderComponentMemo}
				persistTableHead
				highlightOnHover
				progressPending={pending}
				actions={actionsMemo}
			/>
			{modalOn && (
				<div className="fixed inset-0 z-50 bg-black bg-opacity-75 transition-opacity" onClick={handleOKClick}>
					<div className="flex h-screen items-center justify-center ">
						<div
							className="relative mx-3 max-h-[600px] w-full max-w-screen-lg flex-col justify-center overflow-y-auto rounded-xl border-4 border-sky-500 bg-white px-6 py-6 md:mx-0 md:px-8"
							onClick={e => e.stopPropagation()}
						>
							<div className="modal-close z-50 cursor-pointer" onClick={handleOKClick}>
								<svg
									className="absolute right-2 top-2 fill-current"
									xmlns="http://www.w3.org/2000/svg"
									width="18"
									height="18"
									viewBox="0 0 18 18"
								>
									<path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
								</svg>
							</div>
							<div className="mb-5 flex text-center text-xl font-bold uppercase md:text-2xl">Detalles Publicidad</div>
							{adv.loading && <div className="">Cargando...</div>}
							{adv && !adv.loading && !adv.error && (
								<ModalAdv
									client={adv.client}
									clientDesc={adv.clientDesc}
									desc={adv.desc}
									state={adv.state}
									cardsGoogle={cardsGoogle}
									cardsFaceb={cardsFaceb}
								/>
							)}
						</div>
					</div>
				</div>
			)}
			{modalActiveOn && (
				<RemoveComponent
					element={selectedActive}
					setModalOn={setModalActiveOn}
					isSubmittingRemove={isSubmittingActive}
					setIsSubmittingRemove={setIsSubmittingActive}
					typeModal="active"
				></RemoveComponent>
			)}
		</div>
	);
}
