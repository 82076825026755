import {useEffect, useState, useMemo} from "react";
import {Link} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import {clientActions} from "_store";
import DataTable from "react-data-table-component";
import {FilterComponent, ExpandedComponent, ExportButton, RemoveComponent, Header} from "_components";
import {StateEnum, downloadCSV} from "utils";
import {toast} from "react-toastify";

export function ClientsList() {
	const dispatch = useDispatch();
	const {clients, clientRemove} = useSelector(x => x.clients);

	const [clientsState, setClientsState] = useState([]);
	const [filterText, setFilterText] = useState("");
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
	const [pending, setPending] = useState(true);

	const [modalOn, setModalOn] = useState(false);
	const [selectedRemove, setSelectedRemove] = useState();
	const [isSubmittingRemove, setIsSubmittingRemove] = useState(false);

	const columns = [
		{
			name: "Nombre Cliente",
			selector: row => row.name,
			sortable: true,
		},
		{
			name: "Fecha Ingreso",
			selector: row => row.creationDate,
			sortable: true,
		},
		{
			name: "Estado",
			selector: row => row.stateString,
			cell: row => (
				<>
					{row.state === StateEnum.Activo && <span className="rounded-full bg-sky-500 px-1.5 py-0.5 text-xs text-white">Activo</span>}
					{row.state === StateEnum.Inactivo && <span className="rounded-full bg-red-500 px-1.5 py-0.5 text-xs text-white">Inactivo</span>}
				</>
			),
			sortable: true,
		},
		{
			name: "Acciones",
			cell: row => (
				<>
					<Link to={"/clients/edit/" + row.id} title="Editar" className="text-lg">
						<ion-icon name="create-outline"></ion-icon>
					</Link>
					<button onClick={() => clicked(row.id, row.name, "el cliente")} title="Eliminar" className="ml-2 text-lg">
						<ion-icon name="trash-bin-outline"></ion-icon>
					</button>
				</>
			),
		},
	];

	//Load data
	useEffect(() => {
		dispatch(clientActions.getAll());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	//Loading List
	useEffect(() => {
		if (clients.length || clients.error || clients.length === 0) {
			setClientsState(clients);
			setPending(false);
		}
	}, [clients]);

	useEffect(() => {
		if (isSubmittingRemove) {
			dispatch(clientActions.remove({id: selectedRemove.id}));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSubmittingRemove]);

	useEffect(() => {
		if (isSubmittingRemove && !clientRemove.loading && !clientRemove.error) {
			setIsSubmittingRemove(false);
			setModalOn(false);
			setClientsState(
				clientsState.filter(function (item) {
					return item.id !== selectedRemove.id;
				}),
			);
			toast.success("Datos eliminados correctamente.");
		} else if (clientRemove.error) {
			setIsSubmittingRemove(false);
			setModalOn(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [clientRemove]);

	const clicked = (id, title, entity) => {
		setSelectedRemove({
			id,
			title,
			entity,
		});
		setModalOn(true);
	};

	var filteredItems = [];

	if (clientsState.length) {
		filteredItems = clientsState.filter(item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()));
	}

	const subHeaderComponentMemo = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText("");
			}
		};

		return (
			<>
				<FilterComponent
					onFilter={e => setFilterText(e.target.value)}
					onClear={handleClear}
					filterText={filterText}
					placeholder={"Filtrar por Nombre"}
				/>
			</>
		);
	}, [filterText, resetPaginationToggle]);

	const actionsMemo = useMemo(() => {
		return (
			<>
				<Link
					title="Adicionar Cliente"
					className="flex items-center justify-center rounded-md bg-blue-600 px-2 text-white"
					to={"/clients/add"}
				>
					<span className="pr-1">Adicionar</span>
					<ion-icon name="add-circle-outline"></ion-icon>
				</Link>
				<Link
					title="Lista Clientes Eliminados"
					className="flex items-center justify-center rounded-md bg-blue-600 px-2 py-1 text-white"
					to={"/clients/list-removed"}
				>
					<ion-icon name="trash-outline"></ion-icon>
				</Link>
				<ExportButton onExport={() => downloadCSV(clients)} />
			</>
		);
	}, [clients]);

	return (
		<div className="margin-move ml-[300px] p-3">
			<Header />
			{(clients.error || clientRemove.error) && (
				<div className="relative mb-3 rounded border border-red-400 bg-red-100 px-4 py-3 text-red-700" role="alert">
					{clients.error && <label>ERROR: {clients.error.message}</label>}
					{clientRemove.error && <label>ERROR: {clientRemove.error.message}</label>}
				</div>
			)}
			<DataTable
				title="Lista Clientes"
				columns={columns}
				data={filteredItems}
				expandableRows={true}
				expandableRowsComponent={ExpandedComponent}
				pagination
				paginationResetDefaultPage={resetPaginationToggle}
				subHeader
				subHeaderComponent={subHeaderComponentMemo}
				persistTableHead
				highlightOnHover
				progressPending={pending}
				actions={actionsMemo}
				paginationRowsPerPageOptions={[50, 100, 200]}
				paginationPerPage={50}
			/>
			{modalOn && (
				<RemoveComponent
					element={selectedRemove}
					setModalOn={setModalOn}
					isSubmittingRemove={isSubmittingRemove}
					setIsSubmittingRemove={setIsSubmittingRemove}
				></RemoveComponent>
			)}
		</div>
	);
}
