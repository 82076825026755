import {useEffect, useState, useMemo} from "react";
import {useSelector, useDispatch} from "react-redux";
import {webDomainActions} from "_store";
import DataTable from "react-data-table-component";
import {FilterComponent, ExpandedComponent, Header} from "_components";
import {StateEnum} from "utils";

export function WebListRemoved() {
	const dispatch = useDispatch();
	const {webDomainsRemoved} = useSelector(x => x.webDomains);

	const [filterText, setFilterText] = useState("");
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
	const [pending, setPending] = useState(true);

	const columns = [
		{
			name: "Cliente",
			selector: row => (row.clientName != null ? row.clientName : "Compañia"),
			sortable: true,
		},
		{
			name: "Dominio",
			selector: row => row.domain,
			sortable: true,
		},
		{
			name: "Registrador",
			selector: row => row.domainRegistrar,
			sortable: true,
		},
		{
			name: "Propietario",
			selector: row => row.purchasedByCompany,
			cell: row => (
				<>
					{row.purchasedByCompany === 1 ? (
						<span className="rounded-full bg-green-500 px-1.5 py-0.5 text-xs text-white">Compañía</span>
					) : (
						<span className="rounded-full bg-orange-500 px-1.5 py-0.5 text-xs text-white">Cliente</span>
					)}
				</>
			),
			sortable: true,
		},
		{
			name: "Dominio Expira",
			selector: row => row.domainExpirationDate,
			sortable: true,
		},
		{
			name: "Hosting",
			selector: row => row.hosting,
			sortable: true,
		},
		{
			name: "Hosting Expira",
			selector: row => row.hostingExpirationDate,
			sortable: true,
		},
		{
			name: "Estado",
			selector: row => row.state,
			cell: row => (
				<>
					{row.state === StateEnum.Eliminado && (
						<span className="rounded-full bg-orange-500 px-1.5 py-0.5 text-xs text-white">Eliminado</span>
					)}
				</>
			),
			sortable: true,
		},
		{
			name: "Acciones",
			cell: row => <></>,
		},
	];

	//Load data
	useEffect(() => {
		dispatch(webDomainActions.getAllRemoved());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	//Loading List
	useEffect(() => {
		if (webDomainsRemoved.length || webDomainsRemoved.error || webDomainsRemoved.length === 0) {
			setPending(false);
		}
	}, [webDomainsRemoved]);

	function replacer(key, value) {
		if (key === "id") return undefined;
		else if (key === "desc") return undefined;
		else return value;
	}
	var filteredItems = [];
	if (webDomainsRemoved.length) {
		filteredItems = webDomainsRemoved.filter(item => JSON.stringify(item, replacer).toLowerCase().indexOf(filterText.toLowerCase()) !== -1);
	}

	const subHeaderComponentMemo = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText("");
			}
		};

		return (
			<>
				<FilterComponent
					onFilter={e => setFilterText(e.target.value)}
					onClear={handleClear}
					filterText={filterText}
					placeholder={"Filtrar"}
				/>
			</>
		);
	}, [filterText, resetPaginationToggle]);

	const actionsMemo = useMemo(() => {
		return <>{/* <ExportButton onExport={() => downloadCSV(cards)} /> */}</>;
	}, [webDomainsRemoved]);

	return (
		<div className="margin-move ml-[300px] p-3">
			<Header />
			{webDomainsRemoved.error && (
				<div className="relative mb-3 rounded border border-red-400 bg-red-100 px-4 py-3 text-red-700" role="alert">
					{webDomainsRemoved.error && <label>ERROR: {webDomainsRemoved.error.message}</label>}
				</div>
			)}
			<DataTable
				title="Lista Dominios Web Eliminados"
				columns={columns}
				data={filteredItems}
				expandableRows={true}
				expandableRowsComponent={ExpandedComponent}
				pagination
				paginationResetDefaultPage={resetPaginationToggle}
				subHeader
				subHeaderComponent={subHeaderComponentMemo}
				persistTableHead
				highlightOnHover
				progressPending={pending}
				actions={actionsMemo}
			/>
		</div>
	);
}
