import {NavLink, useLocation} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import {authActions} from "_store";
import {useEffect, useState} from "react";

export function SideBar() {
	const location = useLocation();
	const authUser = useSelector(x => x.auth.user);
	const dispatch = useDispatch();
	const logout = () => dispatch(authActions.logout());

	const [usersView, setUsersView] = useState(false);
	const [cardsView, setCardsView] = useState(false);
	const [clientsView, setClientsView] = useState(false);
	const [advView, setAdvView] = useState(false);
	const [domainsView, setDomainsView] = useState(false);
	const [servicesView, setServicesView] = useState(false);

	useEffect(() => {
		if (authUser?.rolesResponse) {
			//SECTION USERS
			authUser.rolesResponse.find(x => x.id === 1) ? setUsersView(true) : setUsersView(false);
			//SECTION CARDS
			authUser.rolesResponse.find(x => x.id === 1 || x.id === 4 || x.id === 2) ? setCardsView(true) : setCardsView(false);
			//SECTION CLIENTS
			authUser.rolesResponse.find(x => x.id === 1 || x.id === 4 || x.id === 3) ? setClientsView(true) : setClientsView(false);
			//SECTION ADV
			authUser.rolesResponse.find(x => x.id === 1 || x.id === 4) ? setAdvView(true) : setAdvView(false);
			//SECTION DOMAINS
			authUser.rolesResponse.find(x => x.id === 1 || x.id === 3) ? setDomainsView(true) : setDomainsView(false);
			//SECTION SERVICES
			authUser.rolesResponse.find(x => x.id === 1 || x.id === 2) ? setServicesView(true) : setServicesView(false);
		}
	}, [authUser]);

	// only show nav when logged in
	if (!authUser) return null;

	function dropdown(element) {
		document.querySelector("." + element + ".submenu")?.classList.toggle("hidden");
		document.querySelector("." + element + " .arrow")?.classList.toggle("!rotate-0");
	}

	return (
		<>
			<div className="sidebar fixed bottom-0 top-0 w-[300px] overflow-y-auto bg-gray-900 p-2 text-center lg:left-0">
				<div className="text-xl text-gray-100">
					<div className="mt-1 flex items-center p-2.5">
						<div className="px-3 py-3">
							<img src="/img/logo-01.png" alt="logo"></img>
						</div>
						<h1 className="ml-3 text-[15px] font-bold text-gray-200">Management</h1>
					</div>
					<div className="my-2 h-[1px] bg-gray-600"></div>
				</div>

				{/* SEARCH */}
				<div className="flex cursor-pointer items-center rounded-md bg-gray-700 p-2.5 px-4 text-white duration-300">
					<ion-icon name="search-outline"></ion-icon>
					<input type="text" placeholder="Search" className="ml-4 w-full bg-transparent text-[15px] focus:outline-none" />
				</div>

				{/* HOME */}
				<NavLink to={"/"} className={({isActive}) => (isActive ? "active" : "")}>
					<div className="mt-3 flex cursor-pointer items-center rounded-md p-2.5 px-4 text-white duration-300 hover:bg-blue-600">
						<ion-icon name="home-outline"></ion-icon>
						<span className="ml-4 text-[15px] font-bold text-gray-200">Home</span>
					</div>
				</NavLink>

				{/* USERS */}
				{usersView && (
					<>
						<div
							className="user-menu mt-3 flex cursor-pointer items-center rounded-md p-2.5 px-4 text-white duration-300 hover:bg-blue-600"
							onClick={() => dropdown("user-menu")}
						>
							<ion-icon name="person-outline"></ion-icon>
							<div className="flex w-full items-center justify-between">
								<span className="ml-4 text-[15px] font-bold text-gray-200">Usuarios</span>
								<span className={`arrow rotate-180 text-sm ${location.pathname.includes("users") ? "" : "!rotate-0"}`}>
									<ion-icon name="chevron-down-outline"></ion-icon>
								</span>
							</div>
						</div>
						<div
							className={`user-menu submenu mx-auto mt-2 w-4/5 text-left text-sm font-bold text-gray-200 ${
								location.pathname.includes("users") ? "" : "hidden"
							}`}
						>
							<NavLink to={"/users/list"} className={({isActive}) => (isActive ? "active" : "")}>
								<h1 className="mt-1 cursor-pointer rounded-md p-2 hover:bg-blue-600">Lista</h1>
							</NavLink>
							<NavLink to={"/users/add"} className={({isActive}) => (isActive ? "active" : "")}>
								<h1 className="mt-1 cursor-pointer rounded-md p-2 hover:bg-blue-600">Adicionar</h1>
							</NavLink>
						</div>
					</>
				)}

				{/* CARDS */}
				{cardsView && (
					<>
						<div
							className="cards-menu mt-3 flex cursor-pointer items-center rounded-md p-2.5 px-4 text-white duration-300 hover:bg-blue-600"
							onClick={() => dropdown("cards-menu")}
						>
							<ion-icon name="card-outline"></ion-icon>
							<div className="flex w-full items-center justify-between">
								<span className="ml-4 text-[15px] font-bold text-gray-200">Tarjetas</span>
								<span className={`arrow rotate-180 text-sm ${location.pathname.includes("cards") ? "" : "!rotate-0"}`}>
									<ion-icon name="chevron-down-outline"></ion-icon>
								</span>
							</div>
						</div>
						<div
							className={`cards-menu submenu mx-auto mt-2 w-4/5 text-left text-sm font-bold text-gray-200 ${
								location.pathname.includes("cards") ? "" : "hidden"
							}`}
						>
							<NavLink to={"/cards/list"} className={({isActive}) => (isActive ? "active" : "")}>
								<h1 className="mt-1 cursor-pointer rounded-md p-2 hover:bg-blue-600">Lista</h1>
							</NavLink>
							<NavLink to={"/cards/list-removed"} className={({isActive}) => (isActive ? "active" : "")}>
								<h1 className="mt-1 cursor-pointer rounded-md p-2 hover:bg-blue-600">Eliminados</h1>
							</NavLink>
							<NavLink to={"/cards/add"} className={({isActive}) => (isActive ? "active" : "")}>
								<h1 className="mt-1 cursor-pointer rounded-md p-2 hover:bg-blue-600">Adicionar</h1>
							</NavLink>
						</div>
					</>
				)}

				{/* CLIENTS */}
				{clientsView && (
					<>
						<div
							className="clients-menu mt-3 flex cursor-pointer items-center rounded-md p-2.5 px-4 text-white duration-300 hover:bg-blue-600"
							onClick={() => dropdown("clients-menu")}
						>
							<ion-icon name="person-outline"></ion-icon>
							<div className="flex w-full items-center justify-between">
								<span className="ml-4 text-[15px] font-bold text-gray-200">Clientes</span>
								<span className={`arrow rotate-180 text-sm ${location.pathname.includes("clients") ? "" : "!rotate-0"}`}>
									<ion-icon name="chevron-down-outline"></ion-icon>
								</span>
							</div>
						</div>
						<div
							className={`clients-menu submenu mx-auto mt-2 w-4/5 text-left text-sm font-bold text-gray-200 ${
								location.pathname.includes("clients") ? "" : "hidden"
							}`}
						>
							<NavLink to={"/clients/list"} className={({isActive}) => (isActive ? "active" : "")}>
								<h1 className="mt-1 cursor-pointer rounded-md p-2 hover:bg-blue-600">Lista</h1>
							</NavLink>
							<NavLink to={"/clients/list-removed"} className={({isActive}) => (isActive ? "active" : "")}>
								<h1 className="mt-1 cursor-pointer rounded-md p-2 hover:bg-blue-600">Eliminados</h1>
							</NavLink>
							<NavLink to={"/clients/add"} className={({isActive}) => (isActive ? "active" : "")}>
								<h1 className="mt-1 cursor-pointer rounded-md p-2 hover:bg-blue-600">Adicionar</h1>
							</NavLink>
						</div>
					</>
				)}

				{/* ADV */}
				{advView && (
					<>
						<div
							className="adv-menu mt-3 flex cursor-pointer items-center rounded-md p-2.5 px-4 text-white duration-300 hover:bg-blue-600"
							onClick={() => dropdown("adv-menu")}
						>
							<ion-icon name="megaphone-outline"></ion-icon>
							<div className="flex w-full items-center justify-between">
								<span className="ml-4 text-[15px] font-bold text-gray-200">Publicidad Sources</span>
								<span className={`arrow rotate-180 text-sm ${location.pathname.includes("advertising") ? "" : "!rotate-0"}`}>
									<ion-icon name="chevron-down-outline"></ion-icon>
								</span>
							</div>
						</div>
						<div
							className={`adv-menu submenu mx-auto mt-2 w-4/5 text-left text-sm font-bold text-gray-200 ${
								location.pathname.includes("advertising") ? "" : "hidden"
							}`}
						>
							<NavLink to={"/advertising/list"} className={({isActive}) => (isActive ? "active" : "")}>
								<h1 className="mt-1 cursor-pointer rounded-md p-2 hover:bg-blue-600">Lista</h1>
							</NavLink>
							<NavLink to={"/advertising/list-removed"} className={({isActive}) => (isActive ? "active" : "")}>
								<h1 className="mt-1 cursor-pointer rounded-md p-2 hover:bg-blue-600">Eliminados</h1>
							</NavLink>
							<NavLink to={"/advertising/add"} className={({isActive}) => (isActive ? "active" : "")}>
								<h1 className="mt-1 cursor-pointer rounded-md p-2 hover:bg-blue-600">Adicionar</h1>
							</NavLink>
						</div>
					</>
				)}

				{/* DOMAINS */}
				{domainsView && (
					<>
						<div
							className="web-menu mt-3 flex cursor-pointer items-center rounded-md p-2.5 px-4 text-white duration-300 hover:bg-blue-600"
							onClick={() => dropdown("web-menu")}
						>
							<ion-icon name="globe-outline"></ion-icon>
							<div className="flex w-full items-center justify-between">
								<span className="ml-4 text-[15px] font-bold text-gray-200">Dominios Web</span>
								<span className={`arrow rotate-180 text-sm ${location.pathname.includes("webdomain") ? "" : "!rotate-0"}`}>
									<ion-icon name="chevron-down-outline"></ion-icon>
								</span>
							</div>
						</div>
						<div
							className={`web-menu submenu mx-auto mt-2 w-4/5 text-left text-sm font-bold text-gray-200 ${
								location.pathname.includes("webdomain") ? "" : "hidden"
							}`}
						>
							<NavLink to={"/webdomain/list"} className={({isActive}) => (isActive ? "active" : "")}>
								<h1 className="mt-1 cursor-pointer rounded-md p-2 hover:bg-blue-600">Lista</h1>
							</NavLink>
							<NavLink to={"/webdomain/list-removed"} className={({isActive}) => (isActive ? "active" : "")}>
								<h1 className="mt-1 cursor-pointer rounded-md p-2 hover:bg-blue-600">Eliminados</h1>
							</NavLink>
							<NavLink to={"/webdomain/add"} className={({isActive}) => (isActive ? "active" : "")}>
								<h1 className="mt-1 cursor-pointer rounded-md p-2 hover:bg-blue-600">Adicionar</h1>
							</NavLink>
						</div>
					</>
				)}

				{/* SERVICES */}
				{servicesView && (
					<>
						<div
							className="devs-services-menu mt-3 flex cursor-pointer items-center rounded-md p-2.5 px-4 text-white duration-300 hover:bg-blue-600"
							onClick={() => dropdown("devs-services-menu")}
						>
							<ion-icon name="briefcase-outline"></ion-icon>
							<div className="flex w-full items-center justify-between">
								<span className="ml-4 text-[15px] font-bold text-gray-200">Servicios Externos</span>
								<span className={`arrow rotate-180 text-sm ${location.pathname.includes("ext-services") ? "" : "!rotate-0"}`}>
									<ion-icon name="chevron-down-outline"></ion-icon>
								</span>
							</div>
						</div>
						<div
							className={`devs-services-menu submenu mx-auto mt-2 w-4/5 text-left text-sm font-bold text-gray-200 ${
								location.pathname.includes("ext-services") ? "" : "hidden"
							}`}
						>
							<NavLink to={"/ext-services/list"} className={({isActive}) => (isActive ? "active" : "")}>
								<h1 className="mt-1 cursor-pointer rounded-md p-2 hover:bg-blue-600">Lista</h1>
							</NavLink>
							<NavLink to={"/ext-services/list-removed"} className={({isActive}) => (isActive ? "active" : "")}>
								<h1 className="mt-1 cursor-pointer rounded-md p-2 hover:bg-blue-600">Eliminados</h1>
							</NavLink>
							<NavLink to={"/ext-services/add"} className={({isActive}) => (isActive ? "active" : "")}>
								<h1 className="mt-1 cursor-pointer rounded-md p-2 hover:bg-blue-600">Adicionar</h1>
							</NavLink>
						</div>
					</>
				)}

				{/* Logout */}
				<div
					onClick={logout}
					className="mt-3 flex cursor-pointer items-center rounded-md p-2.5 px-4 text-white duration-300 hover:bg-blue-600"
				>
					<ion-icon name="log-out-outline"></ion-icon>
					<span className="ml-4 text-[15px] font-bold text-gray-200">Logout</span>
				</div>
			</div>
		</>
	);
}
