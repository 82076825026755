import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {fetchWrapper} from "_helpers";

// create slice

const name = "advertising";
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({name, initialState, extraReducers});

// exports

export const advActions = {...slice.actions, ...extraActions};
export const advsReducer = slice.reducer;

// implementation

function createInitialState() {
	return {
		adv: {},
		advs: {},
		advsRemoved: {},
		advRemove: {},
		advAdd: {},
		advEdit: {},
		advChange: {},
		advActive: {},
	};
}

function createExtraActions() {
	const baseUrl = `${process.env.REACT_APP_API_URL}/advertisings`;

	return {
		get: get(),
		getAll: getAll(),
		add: add(),
		edit: edit(),
		change: change(),
		remove: remove(),
		getAllRemoved: getAllRemoved(),
		active: active(),
	};

	function get() {
		return createAsyncThunk(`${name}/get`, async id => await fetchWrapper.get(`${baseUrl}/${id}`));
	}

	function getAll() {
		return createAsyncThunk(`${name}/getAll`, async () => await fetchWrapper.get(baseUrl));
	}

	function getAllRemoved() {
		return createAsyncThunk(`${name}/getAllRemoved`, async () => await fetchWrapper.get(`${baseUrl}/all-removed`));
	}

	function add() {
		return createAsyncThunk(`${name}/add`, async data => await fetchWrapper.post(`${baseUrl}`, data.data));
	}

	function edit() {
		return createAsyncThunk(`${name}/edit`, async data => await fetchWrapper.put(`${baseUrl}`, data.data));
	}

	function change() {
		return createAsyncThunk(`${name}/change-card`, async data => await fetchWrapper.post(`${baseUrl}/change-card`, data.data));
	}

	function remove() {
		return createAsyncThunk(`${name}/delete`, async ({id}) => await fetchWrapper.delete(`${baseUrl}`, {id}));
	}

	function active() {
		return createAsyncThunk(`${name}/active`, async ({id}) => await fetchWrapper.put(`${baseUrl}/active`, {id}));
	}
}

function createExtraReducers() {
	return {
		...getAll(),
		...get(),
		...add(),
		...edit(),
		...change(),
		...remove(),
		...getAllRemoved(),
		...active(),
	};

	function getAll() {
		var {pending, fulfilled, rejected} = extraActions.getAll;
		return {
			[pending]: state => {
				state.advs = {loading: true};
			},
			[fulfilled]: (state, action) => {
				state.advs = action.payload;
			},
			[rejected]: (state, action) => {
				state.advs = {error: action.error};
			},
		};
	}
	function getAllRemoved() {
		var {pending, fulfilled, rejected} = extraActions.getAllRemoved;
		return {
			[pending]: state => {
				state.advsRemoved = {loading: true};
			},
			[fulfilled]: (state, action) => {
				state.advsRemoved = action.payload;
			},
			[rejected]: (state, action) => {
				state.advsRemoved = {error: action.error};
			},
		};
	}
	function get() {
		var {pending, fulfilled, rejected} = extraActions.get;
		return {
			[pending]: state => {
				state.adv = {loading: true};
			},
			[fulfilled]: (state, action) => {
				state.adv = action.payload;
			},
			[rejected]: (state, action) => {
				state.adv = {error: action.error};
			},
		};
	}
	function add() {
		var {pending, fulfilled, rejected} = extraActions.add;
		return {
			[pending]: state => {
				state.advAdd = {loading: true};
			},
			[fulfilled]: (state, action) => {
				state.advAdd = action.payload;
			},
			[rejected]: (state, action) => {
				state.advAdd = {error: action.error};
			},
		};
	}
	function edit() {
		var {pending, fulfilled, rejected} = extraActions.edit;
		return {
			[pending]: state => {
				state.advEdit = {loading: true};
			},
			[fulfilled]: (state, action) => {
				state.advEdit = action.payload;
			},
			[rejected]: (state, action) => {
				state.advEdit = {error: action.error};
			},
		};
	}
	function change() {
		var {pending, fulfilled, rejected} = extraActions.change;
		return {
			[pending]: state => {
				state.advChange = {loading: true};
			},
			[fulfilled]: (state, action) => {
				state.advChange = action.payload;
			},
			[rejected]: (state, action) => {
				state.advChange = {error: action.error};
			},
		};
	}
	function remove() {
		var {pending, fulfilled, rejected} = extraActions.remove;
		return {
			[pending]: state => {
				state.advRemove = {loading: true};
			},
			[fulfilled]: (state, action) => {
				state.advRemove = action.payload;
			},
			[rejected]: (state, action) => {
				state.advRemove = {error: action.error};
			},
		};
	}
	function active() {
		var {pending, fulfilled, rejected} = extraActions.active;
		return {
			[pending]: state => {
				state.advActive = {loading: true};
			},
			[fulfilled]: (state, action) => {
				state.advActive = action.payload;
			},
			[rejected]: (state, action) => {
				state.advActive = {error: action.error};
			},
		};
	}
}
