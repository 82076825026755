import {useEffect} from "react";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {useSelector, useDispatch} from "react-redux";

import {history} from "_helpers";
import {authActions} from "_store";

export {Login};

function Login() {
	const dispatch = useDispatch();
	const authUser = useSelector(x => x.auth.user);
	const authError = useSelector(x => x.auth.error);

	useEffect(() => {
		// redirect to home if already logged in
		if (authUser) history.navigate("/");

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// form validation rules
	const validationSchema = Yup.object().shape({
		username: Yup.string().required("Username is required"),
		password: Yup.string().required("Password is required"),
	});
	const formOptions = {resolver: yupResolver(validationSchema)};

	// get functions to build form with useForm() hook
	const {register, handleSubmit, formState} = useForm(formOptions);
	const {errors, isSubmitting} = formState;

	function onSubmit({username, password}) {
		return dispatch(authActions.login({username, password}));
	}

	return (
		<div className="flex h-full items-center justify-center">
			<div className="w-full max-w-sm">
				<form onSubmit={handleSubmit(onSubmit)} className="rounded bg-white px-8 pb-8 pt-6 shadow-md">
					<div className="mb-4">
						<label className="mb-2 block text-sm font-bold text-gray-700" htmlFor="username">
							Username
						</label>
						<input
							name="username"
							id="username"
							type="text"
							{...register("username")}
							placeholder="Username"
							className={`focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none ${
								errors.username ? "border-red-500" : ""
							}`}
						/>
						<p className="text-xs italic text-red-500">{errors.username?.message}</p>
					</div>
					<div className="mb-6">
						<label className="mb-2 block text-sm font-bold text-gray-700" htmlFor="password">
							Password
						</label>
						<input
							name="password"
							id="password"
							type="password"
							{...register("password")}
							placeholder="******************"
							className={`focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none ${
								errors.password ? "border-red-500" : ""
							}`}
						/>
						<p className="text-xs italic text-red-500">{errors.password?.message}</p>
					</div>
					<div className="flex items-center justify-between">
						<button
							disabled={isSubmitting}
							className="focus:shadow-outline flex w-full items-center justify-center rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700 focus:outline-none"
						>
							{isSubmitting && (
								<svg
									className="-ml-1 mr-3 h-5 w-5 animate-spin text-white"
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
								>
									<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth={4}></circle>
									<path
										className="opacity-75"
										fill="currentColor"
										d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
									></path>
								</svg>
							)}
							Sign In
						</button>
					</div>
					{authError && (
						<div className="relative mb-0 mt-3 rounded border border-red-400 bg-red-100 px-4 py-3 text-red-700" role="alert">
							<span>{authError.message}</span>
						</div>
					)}
				</form>
			</div>
		</div>
	);
}
