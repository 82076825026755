import {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {useForm, Controller} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {useSelector, useDispatch} from "react-redux";
import {toast} from "react-toastify";
import Select from "react-select";

import {clientActions, webDomainActions} from "_store";
import {Header, LoadingButtons} from "_components";

export function AddWeb() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const {clients, clientAdd} = useSelector(x => x.clients);
	const {webDomainAdd} = useSelector(x => x.webDomains);

	const [submitted, setSubmitted] = useState(false);

	const [selectedClients, setSelectedClients] = useState([]);
	const [addClient, setAddClient] = useState({show: false, idClient: null});

	// form validation rules Adv
	const validationSchema = Yup.object().shape({
		domain: Yup.string().required("This field is required"),
		domainCreationDate: Yup.string().required("This field is required"),
		domainExpirationDate: Yup.string().required("This field is required"),
		hostingCreationDate: Yup.string().required("This field is required"),
		hostingExpirationDate: Yup.string().required("This field is required"),
		client: Yup.object().shape({
			value: Yup.string().required("This field is required"),
		}),
	});

	// form validation rules Client
	const validationSchemaClient = Yup.object().shape({
		name: Yup.string().required("This field is required"),
		desc: Yup.string().required("This field is required"),
	});

	const formOptions = {resolver: yupResolver(validationSchema)};
	const formOptionsClient = {resolver: yupResolver(validationSchemaClient)};

	// get functions to build form with useForm() hook
	const {register, handleSubmit, formState, reset, control, setValue} = useForm(formOptions);
	const {errors, isSubmitting} = formState;

	// get functions to build form with useForm() hook Client
	const {register: registerClient, handleSubmit: handleSubmitClient, formState: formStateClient, reset: resetClient} = useForm(formOptionsClient);
	const {errors: errorsClient, isSubmitting: isSubmittingClient} = formStateClient;

	function onSubmit(data) {
		setSubmitted(true);
		data.clientId = parseInt(data.client.value);
		return dispatch(webDomainActions.add({data}));
	}
	function onSubmitClient({name, desc, state}) {
		setSubmitted(true);
		return dispatch(clientActions.add({name, desc, state}));
	}

	function activeClient() {
		setAddClient(existingValues => ({
			...existingValues,
			show: true,
		}));
	}
	function disabledClient() {
		setAddClient(existingValues => ({
			...existingValues,
			show: false,
		}));
	}

	/*Client*/
	useEffect(() => {
		if (!isSubmittingClient && submitted && !clientAdd.error) {
			resetClient();
			dispatch(clientActions.getAll());
			toast.success("Datos adicionados correctamente.");
			setSubmitted(false);
			setAddClient(existingValues => ({
				...existingValues,
				idClient: clientAdd.id,
			}));
			setValue("client", {value: clientAdd.id});
			disabledClient();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSubmittingClient]);

	useEffect(() => {
		if (!isSubmitting && submitted && !webDomainAdd.error) {
			reset();
			toast.success("Datos adicionados correctamente.");
			setSubmitted(false);
			navigate("/webdomain/list/");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSubmitting]);

	useEffect(() => {
		dispatch(clientActions.getAll());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (clients.length) {
			const options = clients.map(client => ({
				label: client.name,
				value: client.id,
			}));
			setSelectedClients(options);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [clients]);

	return (
		<div className="margin-move ml-[300px] p-3">
			<Header />
			{webDomainAdd.error && (
				<div className="relative mb-3 rounded border border-red-400 bg-red-100 px-4 py-3 text-red-700" role="alert">
					ERROR: {webDomainAdd.error.message}
				</div>
			)}
			<div className="bg-white px-4 pb-5 pt-3">
				<div className="mb-6 text-xl">Agregar Dominio Web</div>
				{clients.loading && <div className="">Cargando...</div>}
				{clients && !clients.loading && !clients.error && (
					<>
						<div className="flex items-start">
							<div className="w-2/3 rounded border p-3">
								<div className="mb-6 text-xl uppercase">Nuevo Dominio Web</div>
								<form onSubmit={handleSubmit(onSubmit)} className="">
									<div className="mb-5 w-2/5">
										<div className="mb-2 flex items-center justify-between">
											<label className="block text-sm font-bold text-gray-700" htmlFor="selectClients">
												Cliente
											</label>
											<button
												type="button"
												onClick={activeClient}
												className="focus:shadow-outline flex items-center justify-center rounded bg-blue-500 px-2 font-bold uppercase text-white hover:bg-blue-700 focus:outline-none"
											>
												Adicionar
											</button>
										</div>
										<Controller
											name="client"
											control={control}
											render={({field}) => (
												<Select
													{...field}
													options={selectedClients}
													value={selectedClients?.find(({value}) => value === addClient.idClient)}
													isDisabled={addClient.idClient ? true : false}
												/>
											)}
										/>
									</div>
									<div className="mb-5 flex">
										<div className="mr-4 w-2/5">
											<label className="mb-2 block text-sm font-bold text-gray-700" htmlFor="domain">
												Dominio
											</label>
											<input
												{...register("domain")}
												placeholder="Dominio"
												className={`focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 focus:outline-none ${
													errors.domain ? "border-red-500" : ""
												}`}
											/>
											<p className="text-xs italic text-red-500">{errors.domain?.message}</p>
										</div>
										<div className="w-2/5">
											<label className="mb-2 block text-sm font-bold text-gray-700" htmlFor="domainRegistrar">
												Registro de Dominio
											</label>
											<div className="relative inline-block w-full">
												<select
													{...register("domainRegistrar")}
													className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
												>
													<option value="GoDaddy">GoDaddy</option>
													<option value="BlueHost">BlueHost</option>
													<option value="Wordpress">Wordpress</option>
												</select>
												<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
													<svg className="h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
														<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
													</svg>
												</div>
											</div>
										</div>
									</div>
									<div className="mb-5 flex">
										<div className="mr-4 w-2/5">
											<label className="mb-2 block text-sm font-bold text-gray-700" htmlFor="purchasedByCompany">
												Propietario de Dominio
											</label>
											<div className="relative inline-block w-full">
												<select
													{...register("purchasedByCompany")}
													className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
												>
													<option value="0">Cliente</option>
													<option value="1">Compañía</option>
												</select>
												<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
													<svg className="h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
														<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
													</svg>
												</div>
											</div>
										</div>
										<div className="w-2/5">
											<label className="mb-2 block text-sm font-bold text-gray-700" htmlFor="state">
												Acceso al Dominio
											</label>
											<div className="relative inline-block w-full">
												<select
													{...register("domainAcces")}
													className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
												>
													<option value="0">Directo</option>
													<option value="1">Delegado</option>
												</select>
												<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
													<svg className="h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
														<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
													</svg>
												</div>
											</div>
										</div>
									</div>
									<div className="mb-5 flex">
										<div className="mr-4 w-2/5">
											<label className="mb-2 block text-sm font-bold text-gray-700" htmlFor="domainCreationDate">
												Fecha Inicio Dominio
											</label>
											<input
												type={"date"}
												{...register("domainCreationDate")}
												className={`focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none ${
													errors.domainCreationDate ? "border-red-500" : ""
												}`}
											/>
											<p className="text-xs italic text-red-500">{errors.domainCreationDate?.message}</p>
										</div>
										<div className="w-2/5">
											<label className="mb-2 block text-sm font-bold text-gray-700" htmlFor="domainExpirationDate">
												Fecha Expiración Dominio
											</label>
											<input
												type={"date"}
												{...register("domainExpirationDate")}
												className={`focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none ${
													errors.domainExpirationDate ? "border-red-500" : ""
												}`}
											/>
											<p className="text-xs italic text-red-500">{errors.domainExpirationDate?.message}</p>
										</div>
									</div>
									<div className="mb-5 flex">
										<div className="mr-4 w-2/5">
											<label className="mb-2 block text-sm font-bold text-gray-700" htmlFor="hosting">
												Hosting
											</label>
											<div className="relative inline-block w-full">
												<select
													{...register("hosting")}
													className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
												>
													<option value="Server 1">Server 1 (Oficina)</option>
													<option value="Server 2">Server 2 (Nube)</option>
													<option value="BlueHost">BlueHost</option>
													<option value="SiteGround">SiteGround</option>
												</select>
												<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
													<svg className="h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
														<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
													</svg>
												</div>
											</div>
										</div>
										<div className="w-2/5">
											<label className="mb-2 block text-sm font-bold text-gray-700" htmlFor="hosting">
												Tecnología
											</label>
											<div className="relative inline-block w-full">
												<select
													{...register("technology")}
													className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
												>
													<option value="WordPress">WordPress</option>
													<option value=".Net">.NET</option>
													<option value="React">React + WebApi</option>
													<option value="Html">HTML</option>
													<option value="Shopify">Shopify</option>
												</select>
												<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
													<svg className="h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
														<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
													</svg>
												</div>
											</div>
										</div>
									</div>
									<div className="mb-5 flex">
										<div className="mr-4 w-2/5">
											<label className="mb-2 block text-sm font-bold text-gray-700" htmlFor="hostingCreationDate">
												Fecha Inicio Hosting
											</label>
											<input
												type={"date"}
												{...register("hostingCreationDate")}
												className={`focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none ${
													errors.hostingCreationDate ? "border-red-500" : ""
												}`}
											/>
											<p className="text-xs italic text-red-500">{errors.hostingCreationDate?.message}</p>
										</div>
										<div className="w-2/5">
											<label className="mb-2 block text-sm font-bold text-gray-700" htmlFor="hostingExpirationDate">
												Fecha Expiración Hosting
											</label>
											<input
												type={"date"}
												{...register("hostingExpirationDate")}
												className={`focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none ${
													errors.hostingExpirationDate ? "border-red-500" : ""
												}`}
											/>
											<p className="text-xs italic text-red-500">{errors.hostingExpirationDate?.message}</p>
										</div>
									</div>
									<div className="mb-5 w-3/5">
										<label className="mb-2 block text-sm font-bold text-gray-700" htmlFor="desc">
											Descripción
										</label>
										<textarea
											{...register("desc")}
											placeholder="Descripción"
											className={`focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none ${
												errors.desc ? "border-red-500" : ""
											}`}
										/>
										<p className="text-xs italic text-red-500">{errors.desc?.message}</p>
									</div>
									<div className="mb-8 w-2/5">
										<label className="mb-2 block text-sm font-bold text-gray-700" htmlFor="state">
											Estado
										</label>
										<div className="relative inline-block w-full">
											<select
												{...register("state")}
												className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
											>
												<option value="1">Activo</option>
												<option value="0">Inactivo</option>
											</select>
											<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
												<svg className="h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
													<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
												</svg>
											</div>
										</div>
									</div>
									<div className="flex w-64 items-center justify-between">
										<button
											disabled={isSubmitting}
											className="focus:shadow-outline flex w-full items-center justify-center rounded bg-blue-500 px-4 py-2 font-bold uppercase text-white hover:bg-blue-700 focus:outline-none"
										>
											{isSubmitting && <LoadingButtons />}
											Adicionar
										</button>
										<Link
											to={"/webdomain/list/"}
											className="focus:shadow-outline ml-3 flex w-full items-center justify-center rounded bg-slate-400 px-4 py-2 font-bold uppercase text-white hover:bg-slate-500 focus:outline-none"
										>
											Cancelar
										</Link>
									</div>
									<div className=""></div>
								</form>
							</div>
							{addClient.show && (
								<div className="ml-3 w-1/3 rounded border p-3">
									<div className="mb-6 text-xl uppercase">Nuevo Cliente</div>
									<form onSubmit={handleSubmitClient(onSubmitClient)} className="max-w-sm">
										<div className="mb-5">
											<label className="mb-2 block text-sm font-bold text-gray-700" htmlFor="name">
												Nombre
											</label>
											<input
												type="text"
												{...registerClient("name")}
												placeholder="Nombre"
												className={`focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none ${
													errorsClient.name ? "border-red-500" : ""
												}`}
											/>
											<p className="text-xs italic text-red-500">{errorsClient.name?.message}</p>
										</div>
										<div className="mb-5">
											<label className="mb-2 block text-sm font-bold text-gray-700" htmlFor="desc">
												Descripción
											</label>
											<textarea
												{...registerClient("desc")}
												placeholder="Descripción"
												className={`focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none ${
													errorsClient.desc ? "border-red-500" : ""
												}`}
											/>
											<p className="text-xs italic text-red-500">{errorsClient.desc?.message}</p>
										</div>
										<div className="mb-8">
											<label className="mb-2 block text-sm font-bold text-gray-700" htmlFor="state">
												Estado
											</label>
											<div className="relative inline-block w-64">
												<select
													{...registerClient("state")}
													className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
												>
													<option value="1">Activo</option>
													<option value="0">Inactivo</option>
												</select>
												<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
													<svg className="h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
														<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
													</svg>
												</div>
											</div>
										</div>
										<div className="flex w-64 items-center justify-between">
											<button
												disabled={isSubmittingClient}
												className="focus:shadow-outline flex w-full items-center justify-center rounded bg-blue-500 px-4 py-2 font-bold uppercase text-white hover:bg-blue-700 focus:outline-none"
											>
												{isSubmittingClient && <LoadingButtons />}
												Adicionar
											</button>
											<button
												onClick={disabledClient}
												className="focus:shadow-outline ml-3 flex w-full items-center justify-center rounded bg-slate-400 px-4 py-2 font-bold uppercase text-white hover:bg-slate-500 focus:outline-none"
											>
												Cancelar
											</button>
										</div>
									</form>
								</div>
							)}
						</div>
					</>
				)}
				{clients.error && <p>No fue posible cargar los datos.</p>}
			</div>
		</div>
	);
}
