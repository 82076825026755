import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {fetchWrapper} from "_helpers";

// create slice

const name = "cards";
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({name, initialState, extraReducers});

// exports

export const cardActions = {...slice.actions, ...extraActions};
export const cardsReducer = slice.reducer;

// implementation

function createInitialState() {
	return {
		card: {},
		cards: {},
		cardsRemoved: {},
		cardRemove: {},
		cardAdd: {},
		cardEdit: {},
	};
}

function createExtraActions() {
	const baseUrl = `${process.env.REACT_APP_API_URL}/cards`;

	return {
		get: get(),
		getAll: getAll(),
		add: add(),
		edit: edit(),
		remove: remove(),
		getAllRemoved: getAllRemoved(),
	};

	function get() {
		return createAsyncThunk(`${name}/get`, async id => await fetchWrapper.get(`${baseUrl}/${id}`));
	}

	function getAll() {
		return createAsyncThunk(`${name}/getAll`, async () => await fetchWrapper.get(baseUrl));
	}

	function getAllRemoved() {
		return createAsyncThunk(`${name}/getAllRemoved`, async () => await fetchWrapper.get(`${baseUrl}/all-removed`));
	}

	function add() {
		return createAsyncThunk(`${name}/add`, async data => await fetchWrapper.post(`${baseUrl}`, data.data));
	}

	function edit() {
		return createAsyncThunk(`${name}/edit`, async data => await fetchWrapper.put(`${baseUrl}`, data.data));
	}

	function remove() {
		return createAsyncThunk(`${name}/delete`, async ({id}) => await fetchWrapper.delete(`${baseUrl}`, {id}));
	}
}

function createExtraReducers() {
	return {
		...getAll(),
		...getAllRemoved(),
		...get(),
		...add(),
		...edit(),
		...remove(),
	};

	function getAll() {
		var {pending, fulfilled, rejected} = extraActions.getAll;
		return {
			[pending]: state => {
				state.cards = {loading: true};
			},
			[fulfilled]: (state, action) => {
				state.cards = action.payload;
			},
			[rejected]: (state, action) => {
				state.cards = {error: action.error};
			},
		};
	}
	function getAllRemoved() {
		var {pending, fulfilled, rejected} = extraActions.getAllRemoved;
		return {
			[pending]: state => {
				state.cardsRemoved = {loading: true};
			},
			[fulfilled]: (state, action) => {
				state.cardsRemoved = action.payload;
			},
			[rejected]: (state, action) => {
				state.cardsRemoved = {error: action.error};
			},
		};
	}
	function get() {
		var {pending, fulfilled, rejected} = extraActions.get;
		return {
			[pending]: state => {
				state.card = {loading: true};
			},
			[fulfilled]: (state, action) => {
				state.card = action.payload;
			},
			[rejected]: (state, action) => {
				state.card = {error: action.error};
			},
		};
	}
	function add() {
		var {pending, fulfilled, rejected} = extraActions.add;
		return {
			[pending]: state => {
				state.cardAdd = {loading: true};
			},
			[fulfilled]: (state, action) => {
				state.cardAdd = action.payload;
			},
			[rejected]: (state, action) => {
				state.cardAdd = {error: action.error};
			},
		};
	}
	function edit() {
		var {pending, fulfilled, rejected} = extraActions.edit;
		return {
			[pending]: state => {
				state.cardEdit = {loading: true};
			},
			[fulfilled]: (state, action) => {
				state.cardEdit = action.payload;
			},
			[rejected]: (state, action) => {
				state.cardEdit = {error: action.error};
			},
		};
	}
	function remove() {
		var {pending, fulfilled, rejected} = extraActions.remove;
		return {
			[pending]: state => {
				state.cardRemove = {loading: true};
			},
			[fulfilled]: (state, action) => {
				state.cardRemove = action.payload;
			},
			[rejected]: (state, action) => {
				state.cardRemove = {error: action.error};
			},
		};
	}
}
