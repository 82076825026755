import {useEffect, useState, useMemo} from "react";
import {Link} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import {webDomainActions} from "_store";
import DataTable from "react-data-table-component";
import {FilterComponent, ExpandedComponent, RemoveComponent, Header} from "_components";
import {StateEnum} from "utils";
import {toast} from "react-toastify";

export function WebList() {
	const dispatch = useDispatch();
	const {webDomains, webDomainRemove} = useSelector(x => x.webDomains);

	const [webDomainsState, setWebDomainsState] = useState([]);
	const [filterText, setFilterText] = useState("");
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
	const [pending, setPending] = useState(true);

	const [modalOn, setModalOn] = useState(false);
	const [selectedRemove, setSelectedRemove] = useState();
	const [isSubmittingRemove, setIsSubmittingRemove] = useState(false);

	const columns = [
		{
			name: "Cliente",
			selector: row => (row.clientName != null ? row.clientName : "Compañia"),
			sortable: true,
		},
		{
			name: "Dominio",
			selector: row => row.domain,
			sortable: true,
		},
		{
			name: "Registrador",
			selector: row => row.domainRegistrar,
			sortable: true,
		},
		{
			name: "Propietario",
			selector: row => row.purchasedByCompany,
			cell: row => (
				<>
					{row.purchasedByCompany === 1 ? (
						<span className="rounded-full bg-green-500 px-1.5 py-0.5 text-xs text-white">Compañía</span>
					) : (
						<span className="rounded-full bg-orange-500 px-1.5 py-0.5 text-xs text-white">Cliente</span>
					)}
				</>
			),
			sortable: true,
		},
		{
			name: "Dominio Expira",
			selector: row => row.domainExpirationDate,
			cell: row => (
				<>
					{dateNow(row.domainExpirationDate) < 0 ? (
						<span className="font-black text-red-500">{row.domainExpirationDate}</span>
					) : (
						<>
							{Math.floor(dateNow(row.domainExpirationDate) / 1000 / 60 / 60 / 24) < 30 ? (
								<span className="text-orange-500">{row.domainExpirationDate}</span>
							) : (
								<span className="">{row.domainExpirationDate}</span>
							)}
						</>
					)}
				</>
			),
			sortable: true,
		},
		{
			name: "Hosting",
			selector: row => row.hosting,
			sortable: true,
		},
		{
			name: "Hosting Expira",
			selector: row => row.hostingExpirationDate,
			cell: row => (
				<>
					{dateNow(row.hostingExpirationDate) < 0 ? (
						<span className="font-black text-red-500">{row.hostingExpirationDate}</span>
					) : (
						<>
							{Math.floor(dateNow(row.hostingExpirationDate) / 1000 / 60 / 60 / 24) < 30 ? (
								<span className="text-orange-500">{row.hostingExpirationDate}</span>
							) : (
								<span className="">{row.hostingExpirationDate}</span>
							)}
						</>
					)}
				</>
			),
			sortable: true,
		},
		{
			name: "Estado",
			selector: row => row.state,
			cell: row => (
				<>
					{row.state === StateEnum.Activo && <span className="rounded-full bg-sky-500 px-1.5 py-0.5 text-xs text-white">Activo</span>}
					{row.state === StateEnum.Inactivo && <span className="rounded-full bg-red-500 px-1.5 py-0.5 text-xs text-white">Inactivo</span>}
				</>
			),
			sortable: true,
		},
		{
			name: "Acciones",
			cell: row => (
				<>
					<Link to={"/webdomain/edit/" + row.id} title="Editar" className="text-lg">
						<ion-icon name="create-outline"></ion-icon>
					</Link>
					<button onClick={() => clicked(row.id, row.domain, "el Dominio Web")} title="Eliminar" className="ml-2 text-lg">
						<ion-icon name="trash-bin-outline"></ion-icon>
					</button>
				</>
			),
		},
	];

	function dateNow(value) {
		let date = new Date(value);
		return date.getTime() - Date.now();
	}

	//Load data
	useEffect(() => {
		dispatch(webDomainActions.getAll());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	//Loading List
	useEffect(() => {
		if (webDomains.length || webDomains.error || webDomains.length === 0) {
			setWebDomainsState(webDomains);
			setPending(false);
		}
	}, [webDomains]);

	useEffect(() => {
		if (isSubmittingRemove) {
			dispatch(webDomainActions.remove({id: selectedRemove.id}));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSubmittingRemove]);

	useEffect(() => {
		if (isSubmittingRemove && !webDomainRemove.loading && !webDomainRemove.error) {
			setIsSubmittingRemove(false);
			setModalOn(false);
			setWebDomainsState(
				webDomainsState.filter(function (item) {
					return item.id !== selectedRemove.id;
				}),
			);
			toast.success("Datos eliminados correctamente.");
		} else if (webDomainRemove.error) {
			setIsSubmittingRemove(false);
			setModalOn(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [webDomainRemove]);

	const clicked = (id, title, entity) => {
		setSelectedRemove({
			id,
			title,
			entity,
		});
		setModalOn(true);
	};

	function replacer(key, value) {
		if (key === "id") return undefined;
		else if (key === "desc") return undefined;
		else return value;
	}
	var filteredItems = [];
	if (webDomainsState.length) {
		filteredItems = webDomainsState.filter(item => JSON.stringify(item, replacer).toLowerCase().indexOf(filterText.toLowerCase()) !== -1);
	}

	const subHeaderComponentMemo = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText("");
			}
		};

		return (
			<>
				<FilterComponent
					onFilter={e => setFilterText(e.target.value)}
					onClear={handleClear}
					filterText={filterText}
					placeholder={"Filtrar"}
				/>
			</>
		);
	}, [filterText, resetPaginationToggle]);

	const actionsMemo = useMemo(() => {
		return (
			<>
				<Link
					title="Adicionar Dominio Web"
					className="flex items-center justify-center rounded-md bg-blue-600 px-2 text-white"
					to="/webdomain/add"
				>
					<span className="pr-1">Adicionar</span>
					<ion-icon name="add-circle-outline"></ion-icon>
				</Link>
				<Link
					title="Lista Clientes"
					className="flex items-center justify-center rounded-md bg-blue-600 px-2 py-1 text-white"
					to={"/clients/list"}
				>
					<ion-icon name="people-outline"></ion-icon>
				</Link>
				<Link
					title="Lista Dominios Webs Eliminados"
					className="flex items-center justify-center rounded-md bg-blue-600 px-2 py-1 text-white"
					to={"/webdomain/list-removed"}
				>
					<ion-icon name="trash-outline"></ion-icon>
				</Link>
				{/* <ExportButton onExport={() => downloadCSV(cards)} /> */}
			</>
		);
	}, [webDomains]);

	return (
		<div className="margin-move ml-[300px] p-3">
			<Header />
			{(webDomains.error || webDomainRemove.error) && (
				<div className="relative mb-3 rounded border border-red-400 bg-red-100 px-4 py-3 text-red-700" role="alert">
					{webDomains.error && <label>ERROR: {webDomains.error.message}</label>}
					{webDomainRemove.error && <label>ERROR: {webDomainRemove.error.message}</label>}
				</div>
			)}
			<DataTable
				title="Lista Dominios Web"
				columns={columns}
				data={filteredItems}
				expandableRows={true}
				expandableRowsComponent={ExpandedComponent}
				pagination
				paginationResetDefaultPage={resetPaginationToggle}
				subHeader
				subHeaderComponent={subHeaderComponentMemo}
				persistTableHead
				highlightOnHover
				progressPending={pending}
				actions={actionsMemo}
			/>
			{modalOn && (
				<RemoveComponent
					element={selectedRemove}
					setModalOn={setModalOn}
					isSubmittingRemove={isSubmittingRemove}
					setIsSubmittingRemove={setIsSubmittingRemove}
				></RemoveComponent>
			)}
		</div>
	);
}
