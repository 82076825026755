import {useEffect, useState, useMemo} from "react";
import {useSelector, useDispatch} from "react-redux";
import {clientActions} from "_store";
import DataTable from "react-data-table-component";
import {FilterComponent, ExpandedComponent, ExportButton, Header} from "_components";
import {StateEnum, downloadCSV} from "utils";

export function ClientsListRemoved() {
	const dispatch = useDispatch();
	const {clientsRemoved} = useSelector(x => x.clients);

	const [filterText, setFilterText] = useState("");
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
	const [pending, setPending] = useState(true);

	const columns = [
		{
			name: "Nombre Cliente",
			selector: row => row.name,
			sortable: true,
		},
		{
			name: "Fecha Ingreso",
			selector: row => row.creationDate,
			sortable: true,
		},
		{
			name: "Fecha Actualización",
			selector: row => row.updateDate,
			sortable: true,
		},
		{
			name: "Estado",
			selector: row => row.stateString,
			cell: row => (
				<>
					{row.state === StateEnum.Eliminado && (
						<span className="rounded-full bg-orange-500 px-1.5 py-0.5 text-xs text-white">Eliminado</span>
					)}
				</>
			),
			sortable: true,
		},
		{
			name: "Acciones",
			cell: row => <></>,
		},
	];

	//Load data
	useEffect(() => {
		dispatch(clientActions.getAllRemoved());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	//Loading List
	useEffect(() => {
		if (clientsRemoved.length || clientsRemoved.error || clientsRemoved.length === 0) {
			setPending(false);
		}
	}, [clientsRemoved]);

	var filteredItems = [];

	if (clientsRemoved.length) {
		filteredItems = clientsRemoved.filter(item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()));
	}

	const subHeaderComponentMemo = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText("");
			}
		};

		return (
			<>
				<FilterComponent
					onFilter={e => setFilterText(e.target.value)}
					onClear={handleClear}
					filterText={filterText}
					placeholder={"Filtrar por Nombre"}
				/>
			</>
		);
	}, [filterText, resetPaginationToggle]);

	const actionsMemo = useMemo(() => {
		return (
			<>
				<ExportButton onExport={() => downloadCSV(clientsRemoved)} />
			</>
		);
	}, [clientsRemoved]);

	return (
		<div className="margin-move ml-[300px] p-3">
			<Header />
			{clientsRemoved.error && (
				<div className="relative mb-3 rounded border border-red-400 bg-red-100 px-4 py-3 text-red-700" role="alert">
					<label>ERROR: {clientsRemoved.error.message}</label>
				</div>
			)}
			<DataTable
				title="Lista Clientes Eliminados"
				columns={columns}
				data={filteredItems}
				expandableRows={true}
				expandableRowsComponent={ExpandedComponent}
				pagination
				paginationResetDefaultPage={resetPaginationToggle}
				subHeader
				subHeaderComponent={subHeaderComponentMemo}
				persistTableHead
				highlightOnHover
				progressPending={pending}
				actions={actionsMemo}
			/>
		</div>
	);
}
