import {Routes, Route, Navigate, useNavigate, useLocation} from "react-router-dom";

import {history} from "_helpers";
import {PrivateRoute, SideBar} from "_components";

import {ToastContainer} from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import {Home} from "pages/home";
import {Login} from "pages/login";
import {AddClient, EditClient, ClientsList, ClientsListRemoved} from "pages/clients";
import {AddCard, EditCard, CardList, CardsListRemoved} from "pages/cards";
import {AddService, EditService, ServicesList, ServicesListRemoved} from "pages/services";
import {AddAdv, AdvList, AdvListRemoved, ChangeCardGoogle, ChangeCardFacebook} from "pages/advertising";
import {AddUser, UsersList, EditUser} from "pages/users";
import {WebList, WebListRemoved, AddWeb, EditWeb} from "pages/webs";

export {App};

function App() {
	// init custom history object to allow navigation from
	// anywhere in the react app (inside or outside components)
	history.navigate = useNavigate();
	history.location = useLocation();

	return (
		<div className="h-screen bg-gray-100">
			<SideBar />
			<div className="h-full pb-4 pt-4">
				<Routes>
					<Route path="/login" element={<Login />} />
					<Route
						path="/users/list"
						element={
							<PrivateRoute>
								<UsersList />
							</PrivateRoute>
						}
					/>
					<Route
						path="/users/add"
						element={
							<PrivateRoute>
								<AddUser />
							</PrivateRoute>
						}
					/>
					<Route
						path="/users/edit/:id"
						element={
							<PrivateRoute>
								<EditUser />
							</PrivateRoute>
						}
					/>
					<Route
						path="/"
						element={
							<PrivateRoute>
								<Home />
							</PrivateRoute>
						}
					/>
					<Route
						path="/clients/add"
						element={
							<PrivateRoute>
								<AddClient />
							</PrivateRoute>
						}
					/>
					<Route
						path="/clients/edit/:id"
						element={
							<PrivateRoute>
								<EditClient />
							</PrivateRoute>
						}
					/>
					<Route
						path="/clients/list"
						element={
							<PrivateRoute>
								<ClientsList />
							</PrivateRoute>
						}
					/>
					<Route
						path="/clients/list-removed"
						element={
							<PrivateRoute>
								<ClientsListRemoved />
							</PrivateRoute>
						}
					/>
					<Route
						path="/cards/add"
						element={
							<PrivateRoute>
								<AddCard />
							</PrivateRoute>
						}
					/>
					<Route
						path="/cards/edit/:id"
						element={
							<PrivateRoute>
								<EditCard />
							</PrivateRoute>
						}
					/>
					<Route
						path="/cards/list"
						element={
							<PrivateRoute>
								<CardList />
							</PrivateRoute>
						}
					/>
					<Route
						path="/cards/list-removed"
						element={
							<PrivateRoute>
								<CardsListRemoved />
							</PrivateRoute>
						}
					/>
					<Route
						path="/advertising/add"
						element={
							<PrivateRoute>
								<AddAdv />
							</PrivateRoute>
						}
					/>
					<Route
						path="/advertising/change-card-google/:id"
						element={
							<PrivateRoute>
								<ChangeCardGoogle />
							</PrivateRoute>
						}
					/>
					<Route
						path="/advertising/change-card-facebook/:id"
						element={
							<PrivateRoute>
								<ChangeCardFacebook />
							</PrivateRoute>
						}
					/>
					<Route
						path="/advertising/list"
						element={
							<PrivateRoute>
								<AdvList />
							</PrivateRoute>
						}
					/>
					<Route
						path="/advertising/list-removed"
						element={
							<PrivateRoute>
								<AdvListRemoved />
							</PrivateRoute>
						}
					/>
					<Route
						path="/webdomain/list"
						element={
							<PrivateRoute>
								<WebList />
							</PrivateRoute>
						}
					/>
					<Route
						path="/webdomain/list-removed"
						element={
							<PrivateRoute>
								<WebListRemoved />
							</PrivateRoute>
						}
					/>
					<Route
						path="/webdomain/add"
						element={
							<PrivateRoute>
								<AddWeb />
							</PrivateRoute>
						}
					/>
					<Route
						path="/webdomain/edit/:id"
						element={
							<PrivateRoute>
								<EditWeb />
							</PrivateRoute>
						}
					/>

					{/* Route module Developers Software services */}
					<Route
						path="/ext-services/list"
						element={
							<PrivateRoute>
								<ServicesList />
							</PrivateRoute>
						}
					/>
					<Route
						path="/ext-services/add"
						element={
							<PrivateRoute>
								<AddService />
							</PrivateRoute>
						}
					/>
					<Route
						path="/ext-services/edit/:id"
						element={
							<PrivateRoute>
								<EditService />
							</PrivateRoute>
						}
					/>
					<Route
						path="/ext-services/list-removed"
						element={
							<PrivateRoute>
								<ServicesListRemoved />
							</PrivateRoute>
						}
					/>
					<Route path="*" element={<Navigate to="/" />} />
				</Routes>
			</div>
			<ToastContainer
				position="bottom-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="dark"
			/>
		</div>
	);
}
