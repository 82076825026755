import {useSelector} from "react-redux";
import {Header} from "_components";

export {Home};

function Home() {
	const {user: authUser} = useSelector(x => x.auth);

	return (
		<div className="margin-move ml-[300px] p-3">
			<Header />
			{process.env.REACT_APP_TEST === "True" && <h2 className="mb-3 text-xl">*****SITIO TEST*****</h2>}
			<h1>Hi {authUser?.name}!</h1>
			<p>You're logged</p>
		</div>
	);
}
