import {useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useForm, Controller} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {useSelector, useDispatch} from "react-redux";
import {toast} from "react-toastify";
import Select from "react-select";

import {cardActions, servicesActions} from "_store";
import {Header, LoadingButtons} from "_components";
import {CardData} from "utils";

export function EditService() {
	const navigate = useNavigate();
	const {id} = useParams();
	const dispatch = useDispatch();
	const {service, serviceEdit} = useSelector(x => x.services);
	const {cards, cardAdd} = useSelector(x => x.cards);
	const [showCard, setShowCard] = useState(false);
	const [selectCards, setSelectCards] = useState([]);
	const [selectedCard, setSelectedCard] = useState([]);

	const [submitted, setSubmitted] = useState(false);

	// form validation rules Service
	const validationSchema = Yup.object().shape({
		title: Yup.string().required("This field is required"),
		description: Yup.string().required("This field is required"),
		accountOwner: Yup.string().required("This field is required"),
		facturationMode: Yup.string().required("This field is required"),
		price: Yup.number().required("This field is required"),
		state: Yup.number().required("This field is required"),
		serviceCreationDate: Yup.string().required("This field is required"),
	});

	// form validation rules Card
	const validationSchemaCard = Yup.object().shape({
		number: Yup.string().required("This field is required"),
		desc: Yup.string().required("This field is required"),
		isCompany: Yup.string().required("This field is required"),
		state: Yup.string().required("This field is required"),
	});

	const formOptions = {resolver: yupResolver(validationSchema)};
	const formOptionsCard = {resolver: yupResolver(validationSchemaCard)};

	// get functions to build form with useForm() hook
	const {register, handleSubmit, formState, reset, control, setValue} = useForm(formOptions);
	const {errors, isSubmitting} = formState;

	// get functions to build form with useForm() hook Card
	const {register: registerCard, handleSubmit: handleSubmitCard, formState: formStateCard, reset: resetCard} = useForm(formOptionsCard);
	const {errors: errorsCard, isSubmitting: isSubmittingCard} = formStateCard;

	// Function submit Service (EDIT)
	function onSubmit(data) {
		setSubmitted(true);
		data.cardId = parseInt(data.cardId.value);
		if (data.serviceExpirationDate === "") data.serviceExpirationDate = null;
		return dispatch(servicesActions.edit({data}));
	}
	useEffect(() => {
		dispatch(cardActions.getAll());
		dispatch(servicesActions.get(id));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	// effect runs when card state is updated
	useEffect(() => {
		// reset form with card data
		reset(service);
		// load save card
		setSelectedCard(selectCards.find(c => c.value === service.cardId));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [service]);
	useEffect(() => {
		if (!isSubmitting && submitted && !serviceEdit.error) {
			reset();
			toast.success("Datos editados correctamente.");
			setSubmitted(false);
			navigate("/ext-services/list/");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSubmitting]);

	// Function submit Card (ADD)
	function onSubmitCard(data) {
		setSubmitted(true);
		if (data.isCompany === "true") {
			data.isCompany = true;
		} else if (data.isCompany === "false") {
			data.isCompany = false;
		}
		return dispatch(cardActions.add({data}));
	}
	useEffect(() => {
		if (cards.length >= 0) {
			const options = cards.map(card => ({
				label: `${card.number}${CardData(card.cardType, card.cardBank)} - ${card.clientName != null ? card.clientName : "Compañia"}`,
				value: card.id,
			}));
			/* options.unshift({
				label: "No tiene",
				value: 0,
			}); */
			setSelectCards(options);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cards]);
	useEffect(() => {
		if (cardAdd?.id) {
			setSelectedCard(selectCards.find(c => c.value === cardAdd.id));
			setValue("cardId", {value: cardAdd.id});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectCards]);
	useEffect(() => {
		if (!isSubmittingCard && submitted && !cardAdd.error) {
			resetCard();
			dispatch(cardActions.getAll());
			toast.success("Datos adicionados correctamente.");
			setSubmitted(false);
			setShowCard(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSubmittingCard]);

	return (
		<div className="margin-move ml-[300px] p-3">
			<Header />
			{(service.error || serviceEdit?.error) && (
				<div className="relative mb-3 rounded border border-red-400 bg-red-100 px-4 py-3 text-red-700" role="alert">
					{service.error && <label>ERROR: {service.error.message}</label>}
					{serviceEdit.error && <label>ERROR: {serviceEdit.error.message}</label>}
				</div>
			)}
			<div className="bg-white px-4 pb-5 pt-3">
				<div className="mb-6 text-xl">Editar Servicio de Software</div>
				{service.loading && <div className="">Cargando...</div>}
				{service && !service.loading && !service.error && (
					<>
						<div className="flex items-start">
							<div className="w-96 rounded border p-3">
								<div className="mb-6 text-xl uppercase">Editar Servicio</div>
								<form onSubmit={handleSubmit(onSubmit)} className="max-w-sm">
									<div className="mb-5">
										<div className="mb-2">
											<label className="mb-2 block text-sm font-bold text-gray-700" htmlFor="title">
												Título
											</label>
											<input
												{...register("title")}
												placeholder="Servicio"
												className={`focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none ${
													errors.title ? "border-red-500" : ""
												}`}
											/>
											<p className="text-xs italic text-red-500">{errors.title?.message}</p>
										</div>
									</div>

									<div className="mb-2">
										<label className="mb-2 block text-sm font-bold text-gray-700" htmlFor="description">
											Descripción
										</label>
										<textarea
											{...register("description")}
											placeholder="Descripción"
											className={`focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none ${
												errors.description ? "border-red-500" : ""
											}`}
										/>
										<p className="text-xs italic text-red-500">{errors.description?.message}</p>
									</div>

									<div className="mb-5">
										<div className="mb-2">
											<label className="mb-2 block text-sm font-bold text-gray-700" htmlFor="account_owner">
												Cuenta de Usuario
											</label>
											<input
												{...register("accountOwner")}
												placeholder="email del usuario o descripción"
												className={`focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none ${
													errors.accountOwner ? "border-red-500" : ""
												}`}
											/>
											<p className="text-xs italic text-red-500">{errors.accountOwner?.message}</p>
										</div>
									</div>

									<div className="mb-5 flex items-center justify-between">
										<div>
											<label className="mb-2 block text-sm font-bold text-gray-700" htmlFor="state">
												Modo de Facturación
											</label>
											<div className="relative inline-block w-48">
												<select
													{...register("facturationMode")}
													className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
												>
													<option value="monthly">Mensual</option>
													<option value="yearly">Anual</option>
													<option value="one_time">Pago único</option>
													<option value="on_demand">Según uso</option>
												</select>
												<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
													<svg className="h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
														<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
													</svg>
												</div>
											</div>
										</div>
										<div className="ml-2">
											<label className="mb-2 block text-sm font-bold text-gray-700" htmlFor="price">
												Precio
											</label>
											<input
												{...register("price")}
												type="number"
												step="0.01"
												placeholder="Precio"
												className={`focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none ${
													errors.price ? "border-red-500" : ""
												}`}
											/>
										</div>
									</div>

									<div className="mb-5">
										<div className="mb-2 flex items-center justify-between">
											<label className="mb-2 block text-sm font-bold text-gray-700" htmlFor="cardGoogle">
												Tarjeta de Facturación
											</label>
											<button
												onClick={() => setShowCard(true)}
												type="button"
												className="focus:shadow-outline flex items-center justify-center rounded bg-blue-500 px-2 font-bold uppercase text-white hover:bg-blue-700 focus:outline-none"
											>
												Adicionar
											</button>
										</div>
										<Controller
											name="cardId"
											control={control}
											render={({field}) => (
												<Select
													{...field}
													options={selectCards}
													value={selectedCard}
													onChange={selectedOption => {
														setSelectedCard(selectedOption);
														field.onChange(selectedOption);
													}}
												/>
											)}
										/>
										<p className="text-xs italic text-red-500">{errors.cardId?.message}</p>
									</div>

									<div className="mb-5 flex items-center justify-between">
										<div>
											<label className="mb-2 block text-sm font-bold text-gray-700" htmlFor="serviceCreationDate">
												Fecha de Inicio
											</label>
											<div className="relative inline-block">
												<input
													{...register("serviceCreationDate")}
													type="date"
													className={`focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none ${
														errors.serviceCreationDate ? "border-red-500" : ""
													}`}
												/>
											</div>
										</div>
										<div className="ml-2">
											<label className="mb-2 block text-sm font-bold text-gray-700" htmlFor="serviceCreationDate">
												Fecha de Vencimiento
											</label>
											<div className="relative inline-block">
												<input
													{...register("serviceExpirationDate")}
													type="date"
													className={`focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none ${
														errors.serviceCreationDate ? "border-red-500" : ""
													}`}
												/>
											</div>
										</div>
									</div>

									<div className="mb-8">
										<div>
											<label className="mb-2 block text-sm font-bold text-gray-700" htmlFor="state">
												Estado
											</label>
											<div className="relative inline-block w-40">
												<select
													{...register("state")}
													className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
												>
													<option value={1}>Activo</option>
													<option value={0}>Inactivo</option>
												</select>
												<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
													<svg className="h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
														<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
													</svg>
												</div>
											</div>
										</div>
									</div>
									<div className="flex w-64 items-center justify-between">
										<button
											disabled={isSubmitting}
											className="focus:shadow-outline flex w-full items-center justify-center rounded bg-blue-500 px-4 py-2 font-bold uppercase text-white hover:bg-blue-700 focus:outline-none"
										>
											{isSubmitting && <LoadingButtons />}
											Editar
										</button>
										<Link
											to={"/ext-services/list/"}
											onClick={reset}
											className="focus:shadow-outline ml-3 flex w-full items-center justify-center rounded bg-slate-400 px-4 py-2 font-bold uppercase text-white hover:bg-slate-500 focus:outline-none"
										>
											Cancelar
										</Link>
									</div>
								</form>
							</div>
							{showCard && (
								<div className="ml-3 w-96 rounded border p-3">
									<div className="mb-6 text-xl uppercase">Nueva Tarjeta</div>
									<form onSubmit={handleSubmitCard(onSubmitCard, () => console.log("errors"))} className="max-w-sm">
										<div className="mb-5">
											<label className="mb-2 block text-sm font-bold text-gray-700" htmlFor="number">
												Número
											</label>
											<input
												type="text"
												maxLength={4}
												{...registerCard("number")}
												placeholder="Número"
												className={`focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none ${
													errorsCard.number ? "border-red-500" : ""
												}`}
											/>
											<p className="text-xs italic text-red-500">{errorsCard.number?.message}</p>
										</div>
										<div className="mb-5">
											<label className="mb-2 block text-sm font-bold text-gray-700" htmlFor="desc">
												Descripción
											</label>
											<textarea
												{...registerCard("desc")}
												placeholder="Descripción"
												className={`focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none ${
													errorsCard.desc ? "border-red-500" : ""
												}`}
											/>
											<p className="text-xs italic text-red-500">{errorsCard.desc?.message}</p>
										</div>
										<div className="mb-8">
											<label className="mb-2 block text-sm font-bold text-gray-700" htmlFor="isCompany">
												Propiedad
											</label>
											<div className="flex">
												<div className="relative inline-block w-64">
													<select
														{...registerCard("isCompany")}
														className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
													>
														<option value={true}>Compañia</option>
														<option value={false}>Cliente</option>
													</select>
													<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
														<svg className="h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
															<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
														</svg>
													</div>
												</div>
											</div>
										</div>

										<div className="mb-8 flex">
											<div className="mr-4 w-1/2">
												<label className="mb-2 block text-sm font-bold text-gray-700" htmlFor="cardType">
													Tipo de Tarjeta
												</label>
												<div className="relative inline-block w-full">
													<select
														{...registerCard("cardType")}
														className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
													>
														<option value="0">Desconocido</option>
														<option value="1">Visa</option>
														<option value="2">Mastercard</option>
														<option value="3">American Express</option>
													</select>
													<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
														<svg className="h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
															<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
														</svg>
													</div>
												</div>
											</div>
											<div className="w-1/2">
												<label className="mb-2 block text-sm font-bold text-gray-700" htmlFor="cardType">
													Banco Emisor
												</label>
												<div className="relative inline-block w-full">
													<select
														{...registerCard("cardBank")}
														className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
													>
														<option value="0">Desconocido</option>
														<option value="1">CapitalOne</option>
														<option value="2">BankOfAmerica</option>
													</select>
													<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
														<svg className="h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
															<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
														</svg>
													</div>
												</div>
											</div>
										</div>

										<div className="mb-8">
											<label className="mb-2 block text-sm font-bold text-gray-700" htmlFor="state">
												Estado
											</label>
											<div className="relative inline-block w-64">
												<select
													{...registerCard("state")}
													className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
												>
													<option value="1">Activo</option>
													<option value="0">Inactivo</option>
												</select>
												<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
													<svg className="h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
														<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
													</svg>
												</div>
											</div>
										</div>
										<div className="flex w-64 items-center justify-between">
											<button
												disabled={isSubmittingCard}
												type="submit"
												className="focus:shadow-outline flex w-full items-center justify-center rounded bg-blue-500 px-4 py-2 font-bold uppercase text-white hover:bg-blue-700 focus:outline-none"
											>
												{isSubmitting && <LoadingButtons />}
												Adicionar
											</button>
											<button
												onClick={() => {
													resetCard();
													setShowCard(false);
												}}
												className="focus:shadow-outline ml-3 flex w-full items-center justify-center rounded bg-slate-400 px-4 py-2 font-bold uppercase text-white hover:bg-slate-500 focus:outline-none"
											>
												Cancelar
											</button>
										</div>
									</form>
								</div>
							)}
						</div>
					</>
				)}
				{service.error && <p>No fue posible cargar los datos.</p>}
			</div>
		</div>
	);
}
