import {useEffect, useState, useMemo} from "react";
import {Link} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import {cardActions} from "_store";
import DataTable from "react-data-table-component";
import {FilterComponent, ExpandedComponent, ExportButton, RemoveComponent, Header} from "_components";
import {StateEnum, CardTypeEnum, CardBankEnum, CardTypeData, CardBankData, downloadCSV} from "utils";
import {toast} from "react-toastify";

export function CardList() {
	const dispatch = useDispatch();
	const {cards, cardRemove} = useSelector(x => x.cards);

	const [cardsState, setCardsState] = useState([]);
	const [filterText, setFilterText] = useState("");
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
	const [pending, setPending] = useState(true);

	const [modalOn, setModalOn] = useState(false);
	const [selectedRemove, setSelectedRemove] = useState();
	const [isSubmittingRemove, setIsSubmittingRemove] = useState(false);

	const columns = [
		{
			name: "Dueño",
			selector: row => (row.clientName != null ? row.clientName : "Compañía"),
			sortable: true,
		},
		{
			name: "Propiedad",
			selector: row => row.isCompany,
			cell: row =>
				row.isCompany ? (
					<span className="rounded-full bg-green-500 px-1.5 py-0.5 text-xs text-white">Compañía</span>
				) : (
					<span className="rounded-full bg-orange-500 px-1.5 py-0.5 text-xs text-white">Cliente</span>
				),
			sortable: true,
		},
		{
			name: "Número",
			selector: row => row.number,
			sortable: true,
		},
		{
			name: "Tipo",
			selector: row => row.cardType,
			cell: row => (
				<>
					{row.cardType === CardTypeEnum.Desconocido ? (
						<span className="rounded-full bg-red-500 px-1.5 py-0.5 text-xs text-white">Desconocido</span>
					) : (
						<span className="rounded-full bg-sky-500 px-1.5 py-0.5 text-xs text-white">{CardTypeData(row.cardType)}</span>
					)}
				</>
			),
			sortable: true,
		},
		{
			name: "Banco",
			selector: row => row.cardBank,
			cell: row => (
				<>
					{row.cardBank === CardBankEnum.Desconocido ? (
						<span className="rounded-full bg-red-500 px-1.5 py-0.5 text-xs text-white">Desconocido</span>
					) : (
						<span className="rounded-full bg-sky-500 px-1.5 py-0.5 text-xs text-white">{CardBankData(row.cardBank)}</span>
					)}
				</>
			),
			sortable: true,
		},
		{
			name: "Fecha Ingreso",
			selector: row => row.creationDate,
			sortable: true,
		},
		{
			name: "Estado",
			selector: row => row.state,
			cell: row => (
				<>
					{row.state === StateEnum.Activo && <span className="rounded-full bg-sky-500 px-1.5 py-0.5 text-xs text-white">Activo</span>}
					{row.state === StateEnum.Inactivo && <span className="rounded-full bg-red-500 px-1.5 py-0.5 text-xs text-white">Inactivo</span>}
				</>
			),
			sortable: true,
		},
		{
			name: "Acciones",
			cell: row => (
				<>
					<Link to={"/cards/edit/" + row.id} title="Editar" className="text-lg">
						<ion-icon name="create-outline"></ion-icon>
					</Link>
					<button onClick={() => clicked(row.id, row.number, "la tarjeta")} title="Eliminar" className="ml-2 text-lg">
						<ion-icon name="trash-bin-outline"></ion-icon>
					</button>
				</>
			),
		},
	];

	//Load data
	useEffect(() => {
		dispatch(cardActions.getAll());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	//Loading List
	useEffect(() => {
		if (cards.length || cards.error || cards.length === 0) {
			setCardsState(cards);
			setPending(false);
		}
	}, [cards]);

	useEffect(() => {
		if (isSubmittingRemove) {
			dispatch(cardActions.remove({id: selectedRemove.id}));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSubmittingRemove]);

	useEffect(() => {
		if (isSubmittingRemove && !cardRemove.loading && !cardRemove.error) {
			setIsSubmittingRemove(false);
			setModalOn(false);
			setCardsState(
				cardsState.filter(function (item) {
					return item.id !== selectedRemove.id;
				}),
			);
			toast.success("Datos eliminados correctamente.");
		} else if (cardRemove.error) {
			setIsSubmittingRemove(false);
			setModalOn(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cardRemove]);

	const clicked = (id, title, entity) => {
		setSelectedRemove({
			id,
			title,
			entity,
		});
		setModalOn(true);
	};

	function replacer(key, value) {
		if (key === "id") return undefined;
		else if (key === "desc") return undefined;
		else return value;
	}
	var filteredItems = [];
	if (cardsState.length) {
		filteredItems = cardsState.filter(item => JSON.stringify(item, replacer).toLowerCase().indexOf(filterText.toLowerCase()) !== -1);
	}

	const subHeaderComponentMemo = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText("");
			}
		};

		return (
			<>
				<FilterComponent
					onFilter={e => setFilterText(e.target.value)}
					onClear={handleClear}
					filterText={filterText}
					placeholder={"Filtrar"}
				/>
			</>
		);
	}, [filterText, resetPaginationToggle]);

	const actionsMemo = useMemo(() => {
		return (
			<>
				<Link title="Adicionar Tarjeta" className="flex items-center justify-center rounded-md bg-blue-600 px-2 text-white" to="/cards/add">
					<span className="pr-1">Adicionar</span>
					<ion-icon name="add-circle-outline"></ion-icon>
				</Link>
				<Link
					title="Lista Tarjetas Eliminadas"
					className="flex items-center justify-center rounded-md bg-blue-600 px-2 py-1 text-white"
					to={"/cards/list-removed"}
				>
					<ion-icon name="trash-outline"></ion-icon>
				</Link>
				<ExportButton onExport={() => downloadCSV(cards)} />
			</>
		);
	}, [cards]);

	return (
		<div className="margin-move ml-[300px] p-3">
			<Header />
			{(cards.error || cardRemove.error) && (
				<div className="relative mb-3 rounded border border-red-400 bg-red-100 px-4 py-3 text-red-700" role="alert">
					{cards.error && <label>ERROR: {cards.error.message}</label>}
					{cardRemove.error && <label>ERROR: {cardRemove.error.message}</label>}
				</div>
			)}
			<DataTable
				title="Lista Tarjetas"
				columns={columns}
				data={filteredItems}
				expandableRows={true}
				expandableRowsComponent={ExpandedComponent}
				pagination
				paginationResetDefaultPage={resetPaginationToggle}
				subHeader
				subHeaderComponent={subHeaderComponentMemo}
				persistTableHead
				highlightOnHover
				progressPending={pending}
				actions={actionsMemo}
				paginationRowsPerPageOptions={[50, 100, 200]}
				paginationPerPage={50}
			/>
			{modalOn && (
				<RemoveComponent
					element={selectedRemove}
					setModalOn={setModalOn}
					isSubmittingRemove={isSubmittingRemove}
					setIsSubmittingRemove={setIsSubmittingRemove}
				></RemoveComponent>
			)}
		</div>
	);
}
