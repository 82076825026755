import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {fetchWrapper} from "_helpers";

// create slice

const name = "webdomain";
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({name, initialState, extraReducers});

// exports

export const webDomainActions = {...slice.actions, ...extraActions};
export const webDomainReducer = slice.reducer;

// implementation

function createInitialState() {
	return {
		webDomain: {},
		webDomains: {},
		webDomainsRemoved: {},
		webDomainRemove: {},
		webDomainAdd: {},
		webDomainEdit: {},
	};
}

function createExtraActions() {
	const baseUrl = `${process.env.REACT_APP_API_URL}/webdomain`;

	return {
		get: get(),
		getAll: getAll(),
		add: add(),
		edit: edit(),
		remove: remove(),
		getAllRemoved: getAllRemoved(),
	};

	function get() {
		return createAsyncThunk(`${name}/get`, async id => await fetchWrapper.get(`${baseUrl}/${id}`));
	}

	function getAll() {
		return createAsyncThunk(`${name}/getAll`, async () => await fetchWrapper.get(baseUrl));
	}

	function getAllRemoved() {
		return createAsyncThunk(`${name}/getAllRemoved`, async () => await fetchWrapper.get(`${baseUrl}/all-removed`));
	}

	function add() {
		return createAsyncThunk(`${name}/add`, async data => await fetchWrapper.post(`${baseUrl}`, data.data));
	}

	function edit() {
		return createAsyncThunk(`${name}/edit`, async data => await fetchWrapper.put(`${baseUrl}`, data.data));
	}

	function remove() {
		return createAsyncThunk(`${name}/delete`, async ({id}) => await fetchWrapper.delete(`${baseUrl}`, {id}));
	}
}

function createExtraReducers() {
	return {
		...getAll(),
		...getAllRemoved(),
		...get(),
		...add(),
		...edit(),
		...remove(),
	};

	function getAll() {
		var {pending, fulfilled, rejected} = extraActions.getAll;
		return {
			[pending]: state => {
				state.webDomains = {loading: true};
			},
			[fulfilled]: (state, action) => {
				state.webDomains = action.payload;
			},
			[rejected]: (state, action) => {
				state.webDomains = {error: action.error};
			},
		};
	}
	function getAllRemoved() {
		var {pending, fulfilled, rejected} = extraActions.getAllRemoved;
		return {
			[pending]: state => {
				state.webDomainsRemoved = {loading: true};
			},
			[fulfilled]: (state, action) => {
				state.webDomainsRemoved = action.payload;
			},
			[rejected]: (state, action) => {
				state.webDomainsRemoved = {error: action.error};
			},
		};
	}
	function get() {
		var {pending, fulfilled, rejected} = extraActions.get;
		return {
			[pending]: state => {
				state.webDomain = {loading: true};
			},
			[fulfilled]: (state, action) => {
				state.webDomain = action.payload;
			},
			[rejected]: (state, action) => {
				state.webDomain = {error: action.error};
			},
		};
	}
	function add() {
		var {pending, fulfilled, rejected} = extraActions.add;
		return {
			[pending]: state => {
				state.webDomainAdd = {loading: true};
			},
			[fulfilled]: (state, action) => {
				state.webDomainAdd = action.payload;
			},
			[rejected]: (state, action) => {
				state.webDomainAdd = {error: action.error};
			},
		};
	}
	function edit() {
		var {pending, fulfilled, rejected} = extraActions.edit;
		return {
			[pending]: state => {
				state.webDomainEdit = {loading: true};
			},
			[fulfilled]: (state, action) => {
				state.webDomainEdit = action.payload;
			},
			[rejected]: (state, action) => {
				state.webDomainEdit = {error: action.error};
			},
		};
	}
	function remove() {
		var {pending, fulfilled, rejected} = extraActions.remove;
		return {
			[pending]: state => {
				state.webDomainRemove = {loading: true};
			},
			[fulfilled]: (state, action) => {
				state.webDomainRemove = action.payload;
			},
			[rejected]: (state, action) => {
				state.webDomainRemove = {error: action.error};
			},
		};
	}
}
