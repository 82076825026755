import {CardData, StateEnumUpd} from "utils";

export const ModalAdv = ({client, clientDesc, desc, state, cardsGoogle, cardsFaceb}) => (
	<div>
		<div>
			<strong className="mr-2">Cliente:</strong>
			<span>{client}</span>
		</div>
		<div>
			<strong className="mr-2">Descripción Cliente:</strong>
			<span>{clientDesc}</span>
		</div>
		<div>
			<strong className="mr-2">Descripción Publicidad:</strong>
			<span>{desc}</span>
		</div>
		<div>
			<strong className="mr-2">Estado Publicidad:</strong>
			{state === StateEnumUpd.Activo && <span className="rounded-full bg-sky-500 px-1.5 py-0.5 text-xs text-white">Activo</span>}
			{state === StateEnumUpd.Inactivo && <span className="rounded-full bg-red-500 px-1.5 py-0.5 text-xs text-white">Inactivo</span>}
			{state === StateEnumUpd.Eliminado && <span className="rounded-full bg-orange-500 px-1.5 py-0.5 text-xs text-white">Eliminado</span>}
		</div>
		<div className="mt-2 flex">
			<div className="mr-1 w-1/2">
				<strong className="mb-2 text-lg">Histórico Tarjetas Google:</strong>
				{cardsGoogle.map((item, index) => (
					<div className={`mt-1 border p-2 ${item.state ? "bg-sky-100" : "bg-red-100"}`} key={index}>
						<div className="flex items-center">
							<label className="mr-2">
								<strong>Tarjeta {!item.state ? "Anterior" : "Actual"}:</strong>
							</label>
							<div className="flex items-center">
								{item.number === "0" ? (
									<strong>No Tiene</strong>
								) : (
									<>
										<span>
											{item.number}
											{CardData(item.type, item.bank)} - {item.isCompany ? "Compañía" : "Cliente"}
										</span>
										{/* {item.state ? (
											<span className="ml-2 rounded-full bg-sky-500 px-1.5 py-0.5 text-xs text-white">Activo</span>
										) : (
											<span className="ml-2 rounded-full bg-red-500 px-1.5 py-0.5 text-xs text-white">Inactivo</span>
										)} */}
									</>
								)}
							</div>
						</div>
						{item.creationDate && (
							<div>
								<label className="mr-2">
									<strong>Fecha Inicio: </strong>
								</label>
								<span>{item.creationDate.toLocaleString()}</span>
							</div>
						)}
						{!item.state && (
							<div>
								<label className="mr-2">
									<strong>Fecha Fin: </strong>
								</label>
								<span>{item.finishDate.toLocaleString()}</span>
							</div>
						)}
						{item.desc && (
							<div>
								<label className="mr-2">
									<strong>Descripción Tarjeta:</strong>
								</label>
								<span>{item.desc}</span>
							</div>
						)}
						{item.observations && (
							<div>
								<label className="mr-2">
									<strong>Observaciones Cambio:</strong>
								</label>
								<span>{item.observations}</span>
							</div>
						)}
					</div>
				))}
			</div>
			<div className="w-1/2">
				<strong className="mb-2 text-lg">Histórico Tarjetas Facebook:</strong>
				{cardsFaceb.map((item, index) => (
					<div className={`mt-1 border p-2 ${item.state ? "bg-sky-100" : "bg-red-100"}`} key={index}>
						<div className="flex items-center">
							<label className="mr-2">
								<strong>Tarjeta {!item.state ? "Anterior" : "Actual"}:</strong>
							</label>
							<div className="flex items-center">
								{item.number === "0" ? (
									<strong>NO TIENE</strong>
								) : (
									<>
										<span>
											{item.number}
											{CardData(item.type, item.bank)} - {item.isCompany ? "Compañía" : "Cliente"}
										</span>
									</>
								)}
							</div>
						</div>
						{item.creationDate && (
							<div>
								<label className="mr-2">
									<strong>Fecha Inicio: </strong>
								</label>
								<span>{item.creationDate.toLocaleString()}</span>
							</div>
						)}
						{!item.state && (
							<div>
								<label className="mr-2">
									<strong>Fecha Fin: </strong>
								</label>
								<span>{item.finishDate.toLocaleString()}</span>
							</div>
						)}
						{item.desc && (
							<div>
								<label className="mr-2">
									<strong>Descripción Tarjeta:</strong>
								</label>
								<span>{item.desc}</span>
							</div>
						)}
						{item.observations && (
							<div>
								<label className="mr-2">
									<strong>Observaciones Cambio:</strong>
								</label>
								<span>{item.observations}</span>
							</div>
						)}
					</div>
				))}
			</div>
		</div>
	</div>
);
