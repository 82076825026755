import {useEffect, useState, useMemo} from "react";
import {Link} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import {advActions} from "_store";
import DataTable from "react-data-table-component";
import {FilterComponent, ExpandedComponent, ExportButton, ModalAdv, RemoveComponent, Header} from "_components";
import {toast} from "react-toastify";
import {CardData, StateEnumUpd, downloadCSV} from "utils";

export function AdvList() {
	const dispatch = useDispatch();
	const {advs, adv, advRemove} = useSelector(x => x.advs);

	const [advsState, setAdvsState] = useState([]);
	const [filterText, setFilterText] = useState("");
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
	const [pending, setPending] = useState(true);
	const [modalOn, setModalOn] = useState(false);

	const [modalRemoveOn, setModalRemoveOn] = useState(false);
	const [selectedRemove, setSelectedRemove] = useState();
	const [isSubmittingRemove, setIsSubmittingRemove] = useState(false);

	const [cardsFaceb, setCardsFaceb] = useState();
	const [cardsGoogle, setCardsGoogle] = useState();

	const columns = [
		{
			name: "Cliente",
			selector: row => row.client,
			sortable: true,
		},
		{
			name: "Tarjeta Google",
			selector: row => row.cards.map(item => (item.source === 0 ? item.number + " - " + (item.isCompany ? "Compañía" : "Cliente") : "")),
			width: "250px",
			cell: row => (
				<div>
					{row.cards.map((item, index) => (
						<div key={index}>
							{item.source === 0 && (
								<div>
									{item.number === null ? (
										<strong>No Tiene</strong>
									) : (
										`${item.number}${CardData(item.type, item.bank)} - ${item.isCompany ? "Compañía" : "Cliente"}`
									)}
								</div>
							)}
						</div>
					))}
				</div>
			),
			sortable: true,
		},
		{
			name: "Tarjeta Facebook",
			selector: row => row.cards.map(item => (item.source === 1 ? item.number + "-" + (item.isCompany ? "Compañía" : "Cliente") : "")),
			width: "250px",
			cell: row => (
				<div>
					{row.cards.map((item, index) => (
						<div key={index}>
							{item.source === 1 && (
								<div>
									{item.number === null ? (
										<strong>No Tiene</strong>
									) : (
										`${item.number}${CardData(item.type, item.bank)} - ${item.isCompany ? "Compañía" : "Cliente"}`
									)}
								</div>
							)}
						</div>
					))}
				</div>
			),
			sortable: true,
		},
		{
			name: "Fecha Ingreso",
			selector: row => row.creationDate,
			sortable: true,
		},
		{
			name: "Estado",
			selector: row => row.state,
			cell: row => (
				<>
					{row.state === StateEnumUpd.Activo && <span className="rounded-full bg-sky-500 px-1.5 py-0.5 text-xs text-white">Activo</span>}
					{row.state === StateEnumUpd.Inactivo && (
						<span className="rounded-full bg-red-500 px-1.5 py-0.5 text-xs text-white">Inactivo</span>
					)}
				</>
			),
			sortable: true,
		},
		{
			name: "Acciones",
			cell: row => (
				<>
					<button onClick={() => clicked(row.id)} title="Ver Detalles" className="mx-1 text-lg">
						<ion-icon name="eye-outline"></ion-icon>
					</button>
					<button
						onClick={() => clickedRemove(row.id, row.client, "la publicidad-source del cliente")}
						title="Eliminar"
						className="mx-1 text-lg"
					>
						<ion-icon name="trash-bin-outline"></ion-icon>
					</button>
					<Link to={"/advertising/change-card-google/" + row.id} title="Cambiar Tarjeta Google" className="mx-1 text-lg">
						<ion-icon name="logo-google"></ion-icon>
					</Link>
					<Link to={"/advertising/change-card-facebook/" + row.id} title="Cambiar Tarjeta Facebook" className="mx-1 mr-4 text-lg">
						<ion-icon name="logo-facebook"></ion-icon>
					</Link>
				</>
			),
		},
	];

	//Load data
	useEffect(() => {
		dispatch(advActions.getAll());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	//Loading List
	useEffect(() => {
		if (advs.length || advs.error || advs.length === 0) {
			setAdvsState(advs);
			setPending(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [advs]);

	//Load List Face & Google
	useEffect(() => {
		if (adv && !adv.loading && !adv.error) {
			fetchData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [adv]);

	useEffect(() => {
		if (isSubmittingRemove) {
			dispatch(advActions.remove({id: selectedRemove.id}));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSubmittingRemove]);

	useEffect(() => {
		if (isSubmittingRemove && !advRemove.loading && !advRemove.error) {
			setIsSubmittingRemove(false);
			setModalRemoveOn(false);
			setAdvsState(
				advsState.filter(function (item) {
					return item.id !== selectedRemove.id;
				}),
			);
			toast.success("Datos eliminados correctamente.");
		} else if (advRemove.error) {
			setIsSubmittingRemove(false);
			setModalRemoveOn(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [advRemove]);

	const clickedRemove = (id, title, entity) => {
		setSelectedRemove({
			id,
			title,
			entity,
		});
		setModalRemoveOn(true);
	};
	const clicked = id => {
		dispatch(advActions.get(id));
		setModalOn(true);
	};
	const handleOKClick = () => {
		setModalOn(false);
	};

	const fetchData = () => {
		let tempGoogle;
		tempGoogle = [];
		let tempFacebook;
		tempFacebook = [];

		adv.cards?.forEach(item => {
			if (item.source === 0) {
				tempGoogle.push(item);
			} else tempFacebook.push(item);
		});
		setCardsGoogle(tempGoogle);
		setCardsFaceb(tempFacebook);
	};

	function replacer(key, value) {
		if (key === "id") return undefined;
		else if (key === "desc") return undefined;
		else return value;
	}
	var filteredItems = [];
	if (advsState.length) {
		filteredItems = advsState.filter(item => JSON.stringify(item, replacer).toLowerCase().indexOf(filterText.toLowerCase()) !== -1);
	}

	const subHeaderComponentMemo = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText("");
			}
		};

		return (
			<FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} placeholder={"Filtrar"} />
		);
	}, [filterText, resetPaginationToggle]);

	const actionsMemo = useMemo(() => {
		return (
			<>
				<Link
					title="Adicionar Publicidad"
					className="flex items-center justify-center rounded-md bg-blue-600 px-2 text-white"
					to="/advertising/add"
				>
					<span className="pr-1">Adicionar</span>
					<ion-icon name="add-circle-outline"></ion-icon>
				</Link>
				<Link
					title="Lista Clientes"
					className="flex items-center justify-center rounded-md bg-blue-600 px-2 py-1 text-white"
					to={"/clients/list"}
				>
					<ion-icon name="people-outline"></ion-icon>
				</Link>
				<Link
					title="Lista Tarjetas"
					className="flex items-center justify-center rounded-md bg-blue-600 px-2 py-1 text-white"
					to={"/cards/list"}
				>
					<ion-icon name="card-outline"></ion-icon>
				</Link>
				<Link
					title="Lista Publicidad Eliminados"
					className="flex items-center justify-center rounded-md bg-blue-600 px-2 py-1 text-white"
					to={"/advertising/list-removed"}
				>
					<ion-icon name="trash-outline"></ion-icon>
				</Link>
				<ExportButton onExport={() => downloadCSV(advs)} />
			</>
		);
	}, [advs]);

	return (
		<div className="margin-move ml-[300px] p-3">
			<Header />
			{(advs.error || advRemove.error) && (
				<div className="relative mb-3 rounded border border-red-400 bg-red-100 px-4 py-3 text-red-700" role="alert">
					{advs.error && <label>ERROR: {advs.error.message}</label>}
					{advRemove.error && <label>ERROR: {advRemove.error.message}</label>}
				</div>
			)}
			<DataTable
				title="Lista Publicidad Sources"
				columns={columns}
				data={filteredItems}
				expandableRows={true}
				expandableRowsComponent={ExpandedComponent}
				pagination
				paginationResetDefaultPage={resetPaginationToggle}
				subHeader
				subHeaderComponent={subHeaderComponentMemo}
				persistTableHead
				highlightOnHover
				progressPending={pending}
				actions={actionsMemo}
				paginationRowsPerPageOptions={[50, 100, 200]}
				paginationPerPage={50}
			/>
			{modalOn && (
				<div className="fixed inset-0 z-50 bg-black bg-opacity-75 transition-opacity" onClick={handleOKClick}>
					<div className="flex h-screen items-center justify-center ">
						<div
							className="relative mx-3 max-h-[600px] w-full max-w-screen-lg flex-col justify-center overflow-y-auto rounded-xl border-4 border-sky-500 bg-white px-6 py-6 md:mx-0 md:px-8"
							onClick={e => e.stopPropagation()}
						>
							<div className="modal-close z-50 cursor-pointer" onClick={handleOKClick}>
								<svg
									className="absolute right-2 top-2 fill-current"
									xmlns="http://www.w3.org/2000/svg"
									width="18"
									height="18"
									viewBox="0 0 18 18"
								>
									<path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
								</svg>
							</div>
							<div className="mb-5 flex text-center text-xl font-bold uppercase md:text-2xl">Detalles Publicidad</div>
							{adv.loading && <div className="">Cargando...</div>}
							{adv && !adv.loading && !adv.error && (
								<ModalAdv
									client={adv.client}
									clientDesc={adv.clientDesc}
									desc={adv.desc}
									state={adv.state}
									cardsGoogle={cardsGoogle}
									cardsFaceb={cardsFaceb}
								/>
							)}
						</div>
					</div>
				</div>
			)}
			{modalRemoveOn && (
				<RemoveComponent
					element={selectedRemove}
					setModalOn={setModalRemoveOn}
					isSubmittingRemove={isSubmittingRemove}
					setIsSubmittingRemove={setIsSubmittingRemove}
				></RemoveComponent>
			)}
		</div>
	);
}
